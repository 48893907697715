import { type ContractMenuItem } from "./ContractMenu";

export const emptyContractMenuItem: ContractMenuItem = {
  uuid: "",
  isActive: false,
  subscriptionInfo: {
    name: "",
  },
};

export const ownerCardSingleTestId = "components/fragments/contract/contract-menu/ContractMenu/OwnerCard-single";

export const menuTestId = "components/fragments/contract/contract-menu/ContractMenu/Menu";

export const menuButtonTestId = "components/fragments/contract/contract-menu/ContractMenu/MenuButton";

export const menuListTestId = "components/fragments/contract/contract-menu/ContractMenu/MenuList";

export const menuItemOptionTestId = "components/fragments/contract/contract-menu/ContractMenu/MenuItemOption";

export const ownerCardTestId = "components/fragments/contract/contract-menu/ContractMenu/OwnerCard";
