import { type FC } from "react";
import { Stack } from "@chakra-ui/react";
import {
    toranaRecruitUrl,
    toranaToyHandbookUrl,
    toranaToyShindanUrl,
    toyBatonApplyUrl,
    toysubGifteeUrl,
    toysubInviteUrl,
    toysubStoreUrl,
} from "@/configs/externalUrls";
import { type useGetIndexPagesFamilyCustomerQuery } from "@/pages/_index/__gql__/get-family-customer.gen";
import { Skeleton } from "@/components/fragments/apollo/loader/skeleton";
import { isDefined } from "@/modules/utils/predicate";
import { bannerToyBatonTestId, skeletonToyBatonTestId } from "./const";
import { Banner } from "./__components__/banner/Banner";
import banner_icon_shindan from "~/assets/images/banner_icon_shindan.svg";
import banner_icon_shokai from "~/assets/images/banner_icon_shokai.svg";
import banner_icon_toybaton from "~/assets/images/banner_icon_toybaton.svg";
import banner_icon_store from "~/assets/images/banner_icon_store.svg";
import banner_icon_book from "~/assets/images/banner_icon_book.svg";
import banner_icon_recruit from "~/assets/images/banner_icon_recruit.svg";

interface BannerProps {
  readonly resultFamilyCustomerQuery: Pick<ReturnType<typeof useGetIndexPagesFamilyCustomerQuery>, "loading" | "data">;
}

export const Banners: FC<BannerProps> = ({ resultFamilyCustomerQuery: { loading, data } }) => {
  return (
    <Stack spacing="1rem" mt="1.5rem">
      <Banner
        icon="/images/banner_icon_gift.png"
        title="デジタルギフト券"
        description="トイサブ！をギフトで贈ろう"
        descriptionColor="theme.red.50"
        href={toysubGifteeUrl}
        showBorder
      />
      <Banner
        icon={banner_icon_shindan}
        title="おもちゃプラン診断"
        description="届くおもちゃをシミュレーション"
        descriptionColor="theme.blue.main"
        href={toranaToyShindanUrl}
      />
      <Banner
        icon={banner_icon_shokai}
        title="お友だち紹介プログラム"
        description="1,500円分のギフト券プレゼント"
        descriptionColor="theme.orange.40"
        href={toysubInviteUrl}
      />
      {loading || !isDefined(data?.familyCustomer.uuid) ? (
        <Skeleton data-testid={skeletonToyBatonTestId} py="8rem" />
      ) : (
        <Banner
          icon={banner_icon_toybaton}
          title="不要おもちゃの買取り"
          description="廃棄されるおもちゃを減らそう"
          descriptionColor="theme.green.50"
          href={`${toyBatonApplyUrl}?uuid=${data?.familyCustomer.uuid ?? ""}`}
          testid={bannerToyBatonTestId}
        />
      )}
      <Banner
        icon={banner_icon_store}
        title="Toysub! store"
        description="おもちゃや絵本の販売"
        descriptionColor="theme.red.50"
        href={toysubStoreUrl}
      />
      <Banner
        icon={banner_icon_book}
        title="知育ハンドブックのダウンロード"
        description="月齢ごとの発達と遊びがわかる"
        descriptionColor="theme.yellow.main"
        href={toranaToyHandbookUrl}
      />
        <Banner
            icon={banner_icon_recruit}
            title="トラーナ採用情報"
            description="トイサブ！で働いてみませんか？"
            descriptionColor="theme.blue.main"
            href={toranaRecruitUrl}
        />
    </Stack>
  );
};
