import { useGetIndexPagesMeQuery } from "@/pages/_index/__gql__/get-me.gen";
import { useEffect } from "react";
import { type MeQueryState, useMeQueryStore } from "@/pages/_index/__modules__/store";

export const useGetIndexPagesMeQueryAtom = (
  options?: Parameters<typeof useGetIndexPagesMeQuery>[0]
): ReturnType<typeof useGetIndexPagesMeQuery> => {
  const result = useGetIndexPagesMeQuery(options);
  const setFamilyCustomerAtomState = useMeQueryStore((state: MeQueryState) => state.set);
  useEffect(() => {
    if (result.data !== undefined) {
      setFamilyCustomerAtomState(result.data);
    }
  }, [result.data, setFamilyCustomerAtomState]);
  return result;
};
