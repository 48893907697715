// @ts-nocheck
import * as Types from '../../../gql/generated/graphql.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIndexPagesMeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIndexPagesMeQuery = { __typename?: 'Query', me: { __typename?: 'FamilyAccount', uuid: string, mail: string, isReceiver: boolean, isContractor: boolean } };


export const GetIndexPagesMeDocument = gql`
    query getIndexPagesMe {
  me {
    uuid
    mail
    isReceiver
    isContractor
  }
}
    `;

/**
 * __useGetIndexPagesMeQuery__
 *
 * To run a query within a React component, call `useGetIndexPagesMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndexPagesMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndexPagesMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIndexPagesMeQuery(baseOptions?: Apollo.QueryHookOptions<GetIndexPagesMeQuery, GetIndexPagesMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIndexPagesMeQuery, GetIndexPagesMeQueryVariables>(GetIndexPagesMeDocument, options);
      }
export function useGetIndexPagesMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndexPagesMeQuery, GetIndexPagesMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIndexPagesMeQuery, GetIndexPagesMeQueryVariables>(GetIndexPagesMeDocument, options);
        }
export function useGetIndexPagesMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIndexPagesMeQuery, GetIndexPagesMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIndexPagesMeQuery, GetIndexPagesMeQueryVariables>(GetIndexPagesMeDocument, options);
        }
export type GetIndexPagesMeQueryHookResult = ReturnType<typeof useGetIndexPagesMeQuery>;
export type GetIndexPagesMeLazyQueryHookResult = ReturnType<typeof useGetIndexPagesMeLazyQuery>;
export type GetIndexPagesMeSuspenseQueryHookResult = ReturnType<typeof useGetIndexPagesMeSuspenseQuery>;
export type GetIndexPagesMeQueryResult = Apollo.QueryResult<GetIndexPagesMeQuery, GetIndexPagesMeQueryVariables>;