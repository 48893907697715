import {
  SHIPMENT_STATUS_NOT_STARTED,
  SHIPMENT_STATUS_IN_PROGRESS_PLAN,
  SHIPMENT_STATUS_COMPLETE_PLAN,
  SHIPMENT_STATUS_WAITING_FOR_REVIEW,
  SHIPMENT_STATUS_RESERVED,
  SHIPMENT_STATUS_CUSTOMER_CONFIRM_REQUESTED,
  SHIPMENT_STATUS_CUSTOMER_CONFIRMING,
} from "@/configs/uuids/shipments/statuses";

// おもちゃ準備中かどうか
export const isPreparing = (shipmentStatusUuid: string): boolean => {
  return (
    // 未着手 or プラン中 or レビュー待ち or プラン済み or 顧客確認依頼 or 顧客確認中 or 発送保留
    shipmentStatusUuid === SHIPMENT_STATUS_NOT_STARTED ||
    shipmentStatusUuid === SHIPMENT_STATUS_IN_PROGRESS_PLAN ||
    shipmentStatusUuid === SHIPMENT_STATUS_COMPLETE_PLAN ||
    shipmentStatusUuid === SHIPMENT_STATUS_WAITING_FOR_REVIEW ||
    shipmentStatusUuid === SHIPMENT_STATUS_CUSTOMER_CONFIRM_REQUESTED ||
    shipmentStatusUuid === SHIPMENT_STATUS_CUSTOMER_CONFIRMING ||
    shipmentStatusUuid === SHIPMENT_STATUS_RESERVED
  );
};
