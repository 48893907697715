import { type GetIndexPagesFamilyCustomerQuery } from "@/pages/_index/__gql__/get-family-customer.gen";
import { type GetIndexPagesShipmentsQuery } from "@/pages/_index/__gql__/get-shipments.gen";
import { type GetIndexPagesMeQuery } from "@/pages/_index/__gql__/get-me.gen";
import { type GetPlansPagesIndexQuery } from "@/pages/_index/__gql__/get-plans.gen";
import {createWithEqualityFn} from "zustand/traditional";

export interface FamilyCustomerQueryState {
  readonly familyCustomersQuery: GetIndexPagesFamilyCustomerQuery | undefined;
  readonly set: (newValue: GetIndexPagesFamilyCustomerQuery | undefined) => void;
}
export const useFamilyCustomerQueryStore = createWithEqualityFn<FamilyCustomerQueryState>()((set) => ({
  familyCustomersQuery: undefined,
  set: (newValue: GetIndexPagesFamilyCustomerQuery | undefined) => {
    set({ familyCustomersQuery: newValue });
  },
}), Object.is);

export interface ShipmentsQueryState {
  readonly shipmentsQuery: GetIndexPagesShipmentsQuery | undefined;
  readonly set: (newValue: GetIndexPagesShipmentsQuery | undefined) => void;
}
export const useShipmentsQueryStore = createWithEqualityFn<ShipmentsQueryState>()((set) => ({
  shipmentsQuery: undefined,
  set: (newValue: GetIndexPagesShipmentsQuery | undefined) => {
    set({ shipmentsQuery: newValue });
  },
}), Object.is);

export interface MeQueryState {
  readonly meQuery: GetIndexPagesMeQuery | undefined;
  readonly set: (newValue: GetIndexPagesMeQuery | undefined) => void;
}
export const useMeQueryStore = createWithEqualityFn<MeQueryState>()((set) => ({
  meQuery: undefined,
  set: (newValue: GetIndexPagesMeQuery | undefined) => {
    set({ meQuery: newValue });
  },
}), Object.is);

export interface PlansQueryState {
  readonly plansQuery: GetPlansPagesIndexQuery | undefined;
  readonly set: (newValue: GetPlansPagesIndexQuery | undefined) => void;
}
export const usePlansQueryStore = createWithEqualityFn<PlansQueryState>()((set) => ({
  plansQuery: undefined,
  set: (newValue: GetPlansPagesIndexQuery | undefined) => {
    set({ plansQuery: newValue });
  },
}), Object.is);

export interface SelectedContractIdState {
  readonly selectedContractId: string | undefined;
  readonly set: (newValue: string | undefined) => void;
}
export const selectedContractStore = createWithEqualityFn<SelectedContractIdState>()((set) => ({
  selectedContractId: undefined,
  set: (newValue: string | undefined) => {
    set({ selectedContractId: newValue });
  },
}), Object.is);
