// @ts-nocheck
import * as Types from '../../../gql/generated/graphql.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIndexPagesShipmentsQueryVariables = Types.Exact<{
  familyCustomerContractedPlanUuids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
  isReceiver: Types.Scalars['Boolean']['input'];
}>;


export type GetIndexPagesShipmentsQuery = { __typename?: 'Query', shipments: Array<{ __typename?: 'Shipments', familyCustomerContractedPlanUuid: string, shipments: Array<{ __typename?: 'Shipment', uuid: string, nextAvailableReturnInventoryDate?: string | null, canReturnInventory: boolean, returnApplicationFormUrl?: string, applyReturnAt?: string | null, isShipped?: boolean | null, createdAt?: string | null, shipmentStatus?: { __typename?: 'ShipmentStatus', uuid: string, name: string } | null }> }> };


export const GetIndexPagesShipmentsDocument = gql`
    query getIndexPagesShipments($familyCustomerContractedPlanUuids: [ID!]!, $isReceiver: Boolean!) {
  shipments(
    familyCustomerContractedPlanUuids: $familyCustomerContractedPlanUuids
    filter: {typeUuids: ["5fa257cd-6939-4fee-b634-022f7c87e544", "0cbef109-8a48-4422-a48f-45be0b79c34d"]}
  ) {
    familyCustomerContractedPlanUuid
    shipments {
      uuid
      nextAvailableReturnInventoryDate
      canReturnInventory
      returnApplicationFormUrl @include(if: $isReceiver)
      applyReturnAt
      isShipped
      createdAt
      shipmentStatus {
        uuid
        name
      }
    }
  }
}
    `;

/**
 * __useGetIndexPagesShipmentsQuery__
 *
 * To run a query within a React component, call `useGetIndexPagesShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndexPagesShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndexPagesShipmentsQuery({
 *   variables: {
 *      familyCustomerContractedPlanUuids: // value for 'familyCustomerContractedPlanUuids'
 *      isReceiver: // value for 'isReceiver'
 *   },
 * });
 */
export function useGetIndexPagesShipmentsQuery(baseOptions: Apollo.QueryHookOptions<GetIndexPagesShipmentsQuery, GetIndexPagesShipmentsQueryVariables> & ({ variables: GetIndexPagesShipmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIndexPagesShipmentsQuery, GetIndexPagesShipmentsQueryVariables>(GetIndexPagesShipmentsDocument, options);
      }
export function useGetIndexPagesShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndexPagesShipmentsQuery, GetIndexPagesShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIndexPagesShipmentsQuery, GetIndexPagesShipmentsQueryVariables>(GetIndexPagesShipmentsDocument, options);
        }
export function useGetIndexPagesShipmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIndexPagesShipmentsQuery, GetIndexPagesShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIndexPagesShipmentsQuery, GetIndexPagesShipmentsQueryVariables>(GetIndexPagesShipmentsDocument, options);
        }
export type GetIndexPagesShipmentsQueryHookResult = ReturnType<typeof useGetIndexPagesShipmentsQuery>;
export type GetIndexPagesShipmentsLazyQueryHookResult = ReturnType<typeof useGetIndexPagesShipmentsLazyQuery>;
export type GetIndexPagesShipmentsSuspenseQueryHookResult = ReturnType<typeof useGetIndexPagesShipmentsSuspenseQuery>;
export type GetIndexPagesShipmentsQueryResult = Apollo.QueryResult<GetIndexPagesShipmentsQuery, GetIndexPagesShipmentsQueryVariables>;