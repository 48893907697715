import { MenuList as ChakraMenuList } from "@chakra-ui/react";
import { type ComponentProps, type ReactNode, type FC } from "react";
import { menuListTestId } from "./const";

export type MenuListProps = ComponentProps<typeof ChakraMenuList> & { readonly children: ReactNode };

export const MenuList: FC<MenuListProps> = ({ children, ...rest }) => {
  return (
    <ChakraMenuList
      data-testid={menuListTestId}
      maxH={{
        base: "15rem",
        sm: "15rem",
        md: "20rem",
        lg: "25rem",
        xl: "30rem",
        "2xl": "35rem",
      }}
      minW={{
        base: "15rem",
        lg: "20rem",
      }}
      p="0rem"
      borderWidth="0rem"
      overflow="auto"
      backgroundColor="light.main"
      boxShadow="shadow.main"
      {...rest}
    >
      {children}
    </ChakraMenuList>
  );
};
