import { Typography } from "@/components/essentials/typography";
import { Box } from "@/components/essentials/box";
import { type FC } from "react";
import { Center, Flex, Image } from "@chakra-ui/react";
import { previewImageTestId, previewTestId, previewTypographyTestId } from "./const";
import Planner from "~/assets/images/planner.svg";
import Link from "next/link";
import { Button } from "@/components/essentials/button";
import { pagesPath } from "@/pathpida/generated/$path";
import { type ReturnStatusProps } from "@/pages/_index/__components__/statuses/ReturnStatuses";

interface PreviewProps {
  readonly latestShipment: NonNullable<ReturnStatusProps["data"]>[0] | undefined;
}

export const Preview: FC<PreviewProps> = ({ latestShipment }) => {
  return (
    <Flex
      data-testid={previewTestId}
      flexDir="column"
      gridRowGap={2}
      w="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Image
          src={Planner}
          alt="プランナー"
          bgColor="mono.light.100"
          border="1px"
          borderColor="border.main"
          p="1"
          borderRadius="50%"
          mb="2"
          data-testid={previewImageTestId}
        />
      </Box>
      <Box data-testid={previewTypographyTestId} textAlign="center">
        <Typography variant="display1" mb="2">
          おもちゃプランをご確認ください
        </Typography>
        <Typography variant="text4" color="mono.dark.54" mb="0.3125rem">
          お手持ちのおもちゃに類似したおもちゃが含まれる場合、該当おもちゃの変更を申請することができます。
        </Typography>
      </Box>
      <Center h="3rem">
        <Button
          as={Link}
          href={pagesPath.plans.detail._shipmentId(latestShipment?.uuid ?? "").$url()}
          data-testid="returnInventoryButtonTestId"
          variant="solid"
        >
          プランの確認、申請はこちら
        </Button>
      </Center>
    </Flex>
  );
};
