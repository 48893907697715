// @ts-nocheck
import * as Types from '../../../gql/generated/graphql.gen';

import { gql } from '@apollo/client';
import { PlanCardContainerFragmentFieldsFragmentDoc } from '../../../components/fragments/plan/plan-card-container/__gql__/plan-card-container-fragment.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPlansPagesIndexQueryVariables = Types.Exact<{
  familyCustomerContractedPlanUuids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type GetPlansPagesIndexQuery = { __typename?: 'Query', shipments: Array<{ __typename?: 'Shipments', familyCustomerContractedPlanUuid: string, shipments: Array<{ __typename?: 'Shipment', uuid: string, shippedAt?: string | null, shipmentType?: { __typename?: 'ShipmentType', uuid: string } | null, shipmentStatus?: { __typename?: 'ShipmentStatus', uuid: string } | null, shipmentToys: Array<{ __typename?: 'ShipmentToy', uuid: string, toy: { __typename?: 'Toy', toyImages: Array<{ __typename?: 'ToyImage', path: string, thumbnailImage: { __typename?: 'ThumbnailImage', pathFixed256: string } }> } }>, additionalShipments: Array<{ __typename?: 'Shipment', uuid: string, shippedAt?: string | null, shipmentStatus?: { __typename?: 'ShipmentStatus', uuid: string } | null, shipmentToys: Array<{ __typename?: 'ShipmentToy', uuid: string, toy: { __typename?: 'Toy', toyImages: Array<{ __typename?: 'ToyImage', path: string, thumbnailImage: { __typename?: 'ThumbnailImage', pathFixed256: string } }> } }> }> }> }> };


export const GetPlansPagesIndexDocument = gql`
    query getPlansPagesIndex($familyCustomerContractedPlanUuids: [ID!]!) {
  shipments(
    familyCustomerContractedPlanUuids: $familyCustomerContractedPlanUuids
    filter: {typeUuids: ["5fa257cd-6939-4fee-b634-022f7c87e544", "0cbef109-8a48-4422-a48f-45be0b79c34d"], statusUuids: ["9c27f1b0-a3a1-4243-8915-cc33d927decf", "a51ec23d-6d50-499c-8bf1-99917bf66c46", "4c4f34fe-3afb-43bd-ab97-843fdc2a554e", "3621d1b0-9bd5-4af8-876c-541f98546c1a", "2751d77d-a6f7-464a-ab71-acf3469d73e3"], isShippedAtFilled: true}
    first: 1
  ) {
    familyCustomerContractedPlanUuid
    shipments {
      ...PlanCardContainerFragmentFields
    }
  }
}
    ${PlanCardContainerFragmentFieldsFragmentDoc}`;

/**
 * __useGetPlansPagesIndexQuery__
 *
 * To run a query within a React component, call `useGetPlansPagesIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansPagesIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansPagesIndexQuery({
 *   variables: {
 *      familyCustomerContractedPlanUuids: // value for 'familyCustomerContractedPlanUuids'
 *   },
 * });
 */
export function useGetPlansPagesIndexQuery(baseOptions: Apollo.QueryHookOptions<GetPlansPagesIndexQuery, GetPlansPagesIndexQueryVariables> & ({ variables: GetPlansPagesIndexQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlansPagesIndexQuery, GetPlansPagesIndexQueryVariables>(GetPlansPagesIndexDocument, options);
      }
export function useGetPlansPagesIndexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlansPagesIndexQuery, GetPlansPagesIndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlansPagesIndexQuery, GetPlansPagesIndexQueryVariables>(GetPlansPagesIndexDocument, options);
        }
export function useGetPlansPagesIndexSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPlansPagesIndexQuery, GetPlansPagesIndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPlansPagesIndexQuery, GetPlansPagesIndexQueryVariables>(GetPlansPagesIndexDocument, options);
        }
export type GetPlansPagesIndexQueryHookResult = ReturnType<typeof useGetPlansPagesIndexQuery>;
export type GetPlansPagesIndexLazyQueryHookResult = ReturnType<typeof useGetPlansPagesIndexLazyQuery>;
export type GetPlansPagesIndexSuspenseQueryHookResult = ReturnType<typeof useGetPlansPagesIndexSuspenseQuery>;
export type GetPlansPagesIndexQueryResult = Apollo.QueryResult<GetPlansPagesIndexQuery, GetPlansPagesIndexQueryVariables>;