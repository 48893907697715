import {
  MenuButton,
  Menu,
  MenuOptionGroup,
  MenuItemOption,
  MenuList,
  type MenuProps,
} from "@/components/essentials/menu";
import { type FC, useState } from "react";
import { contractMenuItemFromId } from "./module";
import { OwnerCard } from "@/components/fragments/card/owner-card/OwnerCard";
import {
  emptyContractMenuItem,
  menuButtonTestId,
  menuItemOptionTestId,
  menuListTestId,
  menuTestId,
  ownerCardSingleTestId,
  ownerCardTestId,
} from "./const";

export interface SubscriptionInfo {
  readonly name: string;
}
export interface FamilyChildren {
  readonly birthday?: string | null | undefined;
}
export interface ContractMenuItem {
  readonly uuid: string;
  readonly isActive?: boolean | null | undefined;
  readonly subscriptionInfo?: SubscriptionInfo | null | undefined;
  readonly contractedAt?: string | null | undefined;
  readonly familyChildren?: Array<FamilyChildren | null> | null | undefined;
}
export interface ContractMenuProps extends Omit<MenuProps, "children"> {
  readonly contractPlans: ContractMenuItem[] | null | undefined;
  readonly onChange?: (id: string) => void;
  readonly initialValue?: ContractMenuItem | null | undefined;
}

export const ContractMenu: FC<ContractMenuProps> = ({ contractPlans, onChange, initialValue, ...rest }) => {
  const [selected, setSelected] = useState<ContractMenuItem>(initialValue || emptyContractMenuItem);
  if (contractPlans?.length === 1) {
    return (
      <OwnerCard
        data-testid={ownerCardSingleTestId}
        py="1rem"
        px="1.5rem"
        isActive={initialValue?.isActive ?? false}
        contractName={initialValue?.subscriptionInfo?.name ?? ""}
        contractedAt={initialValue?.contractedAt}
        familyChildrenBirthdays={initialValue?.familyChildren
          ?.filter((familyChild) => familyChild?.birthday)
          .map((familyChild) => familyChild?.birthday ?? "")}
      />
    );
  } else
    return (
      <Menu data-testid={menuTestId} matchWidth {...rest}>
        <MenuButton data-testid={menuButtonTestId} py="1rem" px="1.5rem" color="primary.main" label>
          <OwnerCard
            p={0}
            isActive={selected.isActive ?? false}
            contractName={selected.subscriptionInfo?.name ?? ""}
            contractedAt={selected.contractedAt}
            familyChildrenBirthdays={selected.familyChildren
              ?.filter((familyChild) => familyChild?.birthday)
              .map((familyChild) => familyChild?.birthday ?? "")}
          />
        </MenuButton>
        <MenuList p="0rem" background="light.main" width="100%" data-testid={menuListTestId}>
          <MenuOptionGroup background="light.main" value={selected.uuid}>
            {Array.isArray(contractPlans) &&
              contractPlans.map((plan, key) => (
                <MenuItemOption
                  data-testid={`${menuItemOptionTestId}-${key}`}
                  _active={{ bgColor: "background.main" }}
                  onClick={() => {
                    const menuItem = contractMenuItemFromId(plan.uuid, contractPlans);
                    setSelected({
                      uuid: menuItem?.uuid ?? "",
                      isActive: plan.isActive,
                      subscriptionInfo: plan.subscriptionInfo,
                      contractedAt: plan.contractedAt,
                      familyChildren: plan.familyChildren,
                    });
                    if (onChange !== undefined) onChange(plan.uuid);
                  }}
                  py="1rem"
                  px="1.5rem"
                  value={plan.uuid}
                  key={key}
                >
                  <OwnerCard
                    p={0}
                    data-testid={`${ownerCardTestId}-${key}`}
                    bg="transparent"
                    isActive={plan.isActive ?? false}
                    contractName={plan.subscriptionInfo?.name ?? ""}
                    contractedAt={plan.contractedAt}
                    familyChildrenBirthdays={plan.familyChildren
                      ?.filter((familyChild) => familyChild?.birthday)
                      .map((familyChild) => familyChild?.birthday ?? "")}
                  />
                </MenuItemOption>
              ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
};
