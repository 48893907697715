import {
  SHIPMENT_STATUS_COMPLETE_PLAN,
  SHIPMENT_STATUS_COMPLETE_RETURN_CONFIRMING,
  SHIPMENT_STATUS_COMPLETE_RETURN_REQUESTED,
  SHIPMENT_STATUS_COMPLETE_SHIPMENT,
  SHIPMENT_STATUS_IN_PROGRESS_PLAN,
  SHIPMENT_STATUS_NOT_STARTED,
  SHIPMENT_STATUS_RESERVED,
  SHIPMENT_STATUS_RETURN_CONFIRMING,
  SHIPMENT_STATUS_WAITING_FOR_REVIEW,
  SHIPMENT_STATUS_RETURN_REQUESTED,
  SHIPMENT_STATUS_CUSTOMER_CONFIRM_REQUESTED,
  SHIPMENT_STATUS_CUSTOMER_CONFIRMING,
} from "@/configs/uuids/shipments/statuses";
import type { ReturnStatusProps } from "./ReturnStatuses";
import { type ShipmentStatus } from "./types";
import { datadogLogs } from "@datadog/browser-logs";
import { compareDesc, parse } from "date-fns";

export const switchStatus = (
  shipments: ReturnStatusProps["data"] | undefined,
  me: ReturnStatusProps["me"] | undefined
):
  | {
      status: ShipmentStatus;
      latestShipment: NonNullable<ReturnStatusProps["data"]>[0] | undefined;
    }
  | undefined => {
  const newestShipment = getNewestShipment(shipments);

  if (
    newestShipment !== undefined &&
    // チラ見せ期間: 顧客確認中 && 配送先
    canPreview(newestShipment, me)
  ) {
    return { status: "canPreview", latestShipment: newestShipment };
  }

  const latestShipment = shipments?.[0];
  if (latestShipment === undefined) {
    return undefined;
  }

  const shipmentStatusUuid = latestShipment.shipmentStatus?.uuid;
  if (
    // おもちゃ準備中: 未着手 or プラン中 or レビュー待ち or プラン済み or 顧客確認依頼 or 発送保留
    shipmentStatusUuid === SHIPMENT_STATUS_NOT_STARTED ||
    shipmentStatusUuid === SHIPMENT_STATUS_IN_PROGRESS_PLAN ||
    shipmentStatusUuid === SHIPMENT_STATUS_COMPLETE_PLAN ||
    shipmentStatusUuid === SHIPMENT_STATUS_WAITING_FOR_REVIEW ||
    shipmentStatusUuid === SHIPMENT_STATUS_CUSTOMER_CONFIRM_REQUESTED ||
    shipmentStatusUuid === SHIPMENT_STATUS_RESERVED ||
    (shipmentStatusUuid === SHIPMENT_STATUS_CUSTOMER_CONFIRMING && !me?.isReceiver)
  ) {
    return { status: "planning", latestShipment };
  } else if (
    // おもちゃ交換不可期間: 発送済み && おもちゃ交換不可
    shipmentStatusUuid === SHIPMENT_STATUS_COMPLETE_SHIPMENT &&
    !latestShipment.canReturnInventory
  ) {
    return { status: "notReturnInventory", latestShipment };
  } else if (
    // おもちゃ交換可能期間: 発送済 && おもちゃ交換可能
    shipmentStatusUuid === SHIPMENT_STATUS_COMPLETE_SHIPMENT &&
    latestShipment.canReturnInventory
  ) {
    return { status: "canReturnInventory", latestShipment };
  } else if (
    // おもちゃの交換申請受付済み: 最新号の発送ステータス：返却申請中、返却申請済
    shipmentStatusUuid === SHIPMENT_STATUS_COMPLETE_RETURN_REQUESTED ||
    shipmentStatusUuid === SHIPMENT_STATUS_RETURN_REQUESTED
  ) {
    return { status: "applied", latestShipment };
  } else if (
    // おもちゃの交換申請受付済み: 最新号の発送ステータス：返却確認中、返却確認済
    shipmentStatusUuid === SHIPMENT_STATUS_RETURN_CONFIRMING ||
    shipmentStatusUuid === SHIPMENT_STATUS_COMPLETE_RETURN_CONFIRMING
  ) {
    return { status: "returned", latestShipment };
  } else {
    datadogLogs.logger.error("unreachable code: unexpected shipmentStatus", {
      shipmentStatusUuid,
      canReturnInventory: latestShipment.canReturnInventory,
      applyReturnAt: latestShipment.applyReturnAt,
    });

    return undefined;
  }
};

const getNewestShipment = (
  shipments: ReturnStatusProps["data"] | undefined
): NonNullable<ReturnStatusProps["data"]>[0] | undefined => {
  return shipments?.slice()?.sort((a, b) => {
    return compareDesc(
      parse(a.createdAt ?? "", "yyyy-MM-dd HH:mm:ss", new Date()),
      parse(b.createdAt ?? "", "yyyy-MM-dd HH:mm:ss", new Date())
    );
  })[0];
};

const canPreview = (
  shipment: NonNullable<ReturnStatusProps["data"]>[0],
  me: ReturnStatusProps["me"] | undefined
): boolean => {
  return shipment.shipmentStatus?.uuid === SHIPMENT_STATUS_CUSTOMER_CONFIRMING && me?.isReceiver === true;
};
