import { AuthedLayout } from "@/layouts";
import type { NextPage } from "next";
import { extractContractedPlanUuids } from "./_index/__modules__/array";
import { ContractToContent } from "./_index/__components__/contract-to-content";
import {
  useGetIndexPagesFamilyCustomerQueryAtom,
  useGetIndexPagesShipmentsQueryAtom,
  useGetIndexPagesMeQueryAtom,
} from "./_index/__modules__/hooks";
import { NextSeo } from "next-seo";
import { AuthedLayoutTestId } from "./const";
import { isQueryCompleted } from "@/modules/utils/apollo-wrapper";
import { useApolloError } from "@/hooks/useApolloError";
import { useGetPlansPagesIndexQueryAtom } from "./_index/__modules__/hooks/useGetPlanPagesIndexQueryAtom";
import { Banners } from "./_index/__components__/banners/Banners";
import { NoticeButton } from "@/components/essentials/button/notice-button/NoticeButton";
import { pagesPath } from "@/pathpida/generated/$path";
import { isNonNullable } from "@/modules/utils/predicate";
import { useMemo, useEffect } from "react";
import { isPreparing } from "@/modules/utils/shipments";
import { ExternalLinkButton } from "./_index/__components__/external-link-button/ExternalLinkButton";
import { Box } from "@/components/essentials/box";
import { mobiletargetingUpdateEndpoint, mailTargetingUpdateEndpoint } from "@/modules/utils/amazon-pinpoint";
import { bootChannelTalk } from "@/modules/utils/channel-talk";
import { type GetIndexPagesMeQuery } from "@/pages/_index/__gql__/get-me.gen";
import { type GetIndexPagesFamilyCustomerQuery } from "@/pages/_index/__gql__/get-family-customer.gen";
import cracker from "~/assets/images/cracker.svg";
import train from "~/assets/images/toy-train.svg";
import { toranaPlanExampleUrl, toysubCampaignInfoUrl } from "@/configs/externalUrls";
import { datadogLogs } from "@datadog/browser-logs";

const Index: NextPage = () => {
  const resultFamilyCustomerQuery = useGetIndexPagesFamilyCustomerQueryAtom();
  const resultMeQuery = useGetIndexPagesMeQueryAtom();

  useEffect(() => {
    if (isQueryCompleted([resultFamilyCustomerQuery, resultMeQuery])) {
      datadogLogs.setUser({
        family_customer_uuid: resultFamilyCustomerQuery.data?.familyCustomer.uuid,
        family_account_uuid: resultMeQuery.data?.me.uuid,
        is_contractor: resultMeQuery.data?.me.isContractor,
        is_receiver: resultMeQuery.data?.me.isReceiver,
      });
    }
  }, [resultFamilyCustomerQuery, resultMeQuery]);

  const resultShipmentsQuery = useGetIndexPagesShipmentsQueryAtom({
    skip: !resultFamilyCustomerQuery.data?.familyCustomer.familyCustomerContractedPlans || resultMeQuery.loading,
    variables: {
      familyCustomerContractedPlanUuids:
        extractContractedPlanUuids(resultFamilyCustomerQuery.data?.familyCustomer.familyCustomerContractedPlans) ?? [],
      isReceiver: resultMeQuery.data?.me.isReceiver === true,
    },
  });

  const resultPlansQuery = useGetPlansPagesIndexQueryAtom({
    skip: !resultFamilyCustomerQuery.data?.familyCustomer.familyCustomerContractedPlans || resultMeQuery.loading,
    variables: {
      familyCustomerContractedPlanUuids:
        extractContractedPlanUuids(resultFamilyCustomerQuery.data?.familyCustomer.familyCustomerContractedPlans, {
          first: true,
        }) ?? [],
    },
  });

  const displayEnqueteRemind: boolean = useMemo(() => {
    return (
      isQueryCompleted([resultFamilyCustomerQuery, resultMeQuery, resultShipmentsQuery]) &&
      isNonNullable(resultMeQuery.data) &&
      resultMeQuery.data.me.isReceiver &&
      isNonNullable(resultFamilyCustomerQuery.data) &&
      !resultFamilyCustomerQuery.data.familyCustomer.hasAnsweredSurvey &&
      isNonNullable(resultShipmentsQuery.data) &&
      // 初回発送前の場合のみ表示(発送済みの発送がある場合は表示しない)
      !resultShipmentsQuery.data.shipments.some((contract) =>
        contract.shipments.some(
          (shipment) => isNonNullable(shipment.shipmentStatus) && !isPreparing(shipment.shipmentStatus.uuid)
        )
      )
    );
  }, [resultFamilyCustomerQuery, resultMeQuery, resultShipmentsQuery]);

  useApolloError(
    resultFamilyCustomerQuery.error || resultShipmentsQuery.error || resultMeQuery.error || resultPlansQuery.error
  );

  const execUpdatePinpoint = async (data: GetIndexPagesMeQuery | undefined): Promise<void> => {
    if (!data) {
      return;
    }
    void mobiletargetingUpdateEndpoint({
      id: data.me.uuid,
      userAttributes: {
        mail: data.me.mail,
        membershipType: "CUSTOMER",
      },
    });
    void mailTargetingUpdateEndpoint(data.me.mail, {
      id: data.me.uuid,
      userAttributes: {
        mail: data.me.mail,
        membershipType: "CUSTOMER",
      },
    });
  };

  const bootCustomChannelTalk = async (
    meData: GetIndexPagesMeQuery | undefined,
    familyCustomerData: GetIndexPagesFamilyCustomerQuery | undefined
  ): Promise<void> => {
    if (!meData || !familyCustomerData || !familyCustomerData.familyCustomer.id) {
      return;
    }
    bootChannelTalk(familyCustomerData.familyCustomer.id.toString(), meData.me.mail);
  };

  useEffect(() => {
    void execUpdatePinpoint(resultMeQuery.data);
    void bootCustomChannelTalk(resultMeQuery.data, resultFamilyCustomerQuery.data);
  }, [resultMeQuery, resultFamilyCustomerQuery]);

  return (
    <>
      <NextSeo title="ホーム" description="Toysub! マイページへようこそ！" />
      <AuthedLayout data-testid={AuthedLayoutTestId} footer py="1.5rem" px="1rem">
        {displayEnqueteRemind && (
          <NoticeButton href={pagesPath.questionnaire.$url()} mb="1.5rem">
            お手持ちのおもちゃを教えてください
          </NoticeButton>
        )}
        <ContractToContent
          loadings={{
            contracts: !isQueryCompleted([resultFamilyCustomerQuery]),
            shipments: !isQueryCompleted([resultFamilyCustomerQuery, resultShipmentsQuery]),
            planHistory: !isQueryCompleted([resultFamilyCustomerQuery, resultPlansQuery]),
            me: !isQueryCompleted([resultMeQuery]),
          }}
          onChangeSelectedContractId={(contractId) => {
            void resultPlansQuery.refetch({ familyCustomerContractedPlanUuids: [contractId] });
          }}
        />

        <Box mt="1.5rem">
          <ExternalLinkButton icon={train} linkText="年齢別おすすめおもちゃ一例" href={toranaPlanExampleUrl} />
        </Box>

        <Box mt="1.5rem">
          <ExternalLinkButton icon={cracker} linkText="会員限定お得情報はこちら" href={toysubCampaignInfoUrl} />
        </Box>
        <Banners resultFamilyCustomerQuery={resultFamilyCustomerQuery} />
      </AuthedLayout>
    </>
  );
};

export default Index;
