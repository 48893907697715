import { Typography } from "@/components/essentials/typography";
import { type PartiallyPartial } from "@/modules/utils/types";
import { HStack } from "@chakra-ui/react";
import { type FC } from "react";
import { BaseCard, type BaseCardProps } from "@/components/fragments/card/base-card";
import { Tag } from "@/components/essentials/tag";
import {
  ownerCardChildrenAgeTestId,
  ownerCardContractedAtTestId,
  ownerCardTagTestId,
  ownerCardTestId,
  ownerCardTitleTestId,
} from "./const";
import { formatAsiaTokyoDateToString } from "@/modules/utils/date";
import { buildChildAge } from "@/modules/utils/age";
import { parse } from "date-fns";

export interface OwnerCardProps extends PartiallyPartial<BaseCardProps, "children"> {
  readonly isActive?: boolean;
  readonly contractName?: string;
  readonly contractedAt?: string | null | undefined;
  readonly familyChildrenBirthdays?: string[] | undefined;
}

export const OwnerCard: FC<OwnerCardProps> = (props) => {
  const { isActive, contractName, contractedAt, familyChildrenBirthdays, ...rest } = props;
  return (
    <BaseCard data-testid={ownerCardTestId} {...rest}>
      <HStack spacing="1rem">
        {isActive ? (
          <Tag
            colorScheme="primary"
            variant="solid"
            borderRadius="1rem"
            p="0.5rem"
            minW={14}
            data-testid={ownerCardTagTestId}
          >
            契約中
          </Tag>
        ) : (
          <Tag
            colorScheme="gray"
            variant="solid"
            borderRadius="1rem"
            p="0.5rem"
            minW={14}
            data-testid={ownerCardTagTestId}
          >
            停止中
          </Tag>
        )}
        <Typography variant="display6" data-testid={ownerCardTitleTestId}>
          {contractName ?? "-"}

          {contractedAt && (
            <Typography variant="text4" data-testid={ownerCardContractedAtTestId}>
              契約日：{formatAsiaTokyoDateToString(contractedAt, "yyyy/MM/dd")}
            </Typography>
          )}
          {familyChildrenBirthdays && familyChildrenBirthdays.length > 0 && (
            <Typography variant="text4" data-testid={ownerCardChildrenAgeTestId}>
              年齢：
              {familyChildrenBirthdays
                .map((birthday) => {
                  const ageText = buildChildAge(parse(birthday, "yyyy-MM-dd HH:mm:ss", new Date()), new Date());
                  if (ageText === "不明") return undefined;
                  return ageText;
                })
                .join(", ")}
            </Typography>
          )}
        </Typography>
      </HStack>
    </BaseCard>
  );
};
