import { type BoxProps } from "@chakra-ui/react";
import { Box } from "@/components/essentials/box";
import { type ReactNode, type FC } from "react";

export interface BaseCardProps extends BoxProps {
  children: ReactNode;
}

export const BaseCard: FC<BaseCardProps> = ({ children, ...rest }) => {
  return (
    <Box p="1.5rem" borderRadius="0.5rem" bgColor="mono.light.100" {...rest}>
      {children}
    </Box>
  );
};
