import { BaseCard } from "@/components/fragments/card/base-card";
import { ContractMenu } from "@/components/fragments/contract/contract-menu";
import { findShipmentsFromContractedPlanUuids } from "@/pages/_index/__modules__/array";
import { Box, Stack } from "@chakra-ui/react";
import { type FC, useEffect, useMemo } from "react";
import { ReturnStatutes } from "@/pages/_index/__components__/statuses";
import {
  type FamilyCustomerQueryState,
  type MeQueryState,
  type PlansQueryState,
  type SelectedContractIdState,
  selectedContractStore,
  type ShipmentsQueryState,
  useFamilyCustomerQueryStore,
  useMeQueryStore,
  usePlansQueryStore,
  useShipmentsQueryStore,
} from "@/pages/_index/__modules__/store";
import { Skeleton } from "@/components/fragments/apollo/loader/skeleton";
import {
  BaseCardTestId,
  LabelContractsCountTestId,
  PlanStackTestId,
  SkeletonMenuTestId,
  SkeletonPlanCardTestId,
  SkeletonReturnStatutesTestId,
} from "./const";
import { PlanCardContainer, PlanEmptyBox } from "@/components/fragments/plan";
import { PlanHistoryButton } from "@/pages/_index/__components__/plan-history/PlanHistoryButton";
import { getViewableShipmentPlansByContractedPlan } from "@/pages/_index/__modules__/plan-history";
import { Typography } from "@/components/essentials/typography";
import { sortFamilyCustomerContractedPlan } from "./module";
import { type GetIndexPagesFamilyCustomerQuery } from "@/pages/_index/__gql__/get-family-customer.gen";
import { FAMILY_CUSTOMER_STATUS_QUIT_APPLICATION_SUBMITTED } from "@/configs/uuids/family-customer-status/familyCustomerStatus";
import { QuitApplicationSubmitted } from "@/pages/_index/__components__/statuses/switch-statuses/quit-application-submitted";
import { useGetQuitApplicationPagesIndexQuery } from "@/pages/_index/__gql__/get-quit-application.gen";
import { parse } from "date-fns";
import { QuitApplicationApplicableStatus } from "@/gql/generated/graphql.gen";

interface ContractToToContentProps {
  readonly loadings: {
    contracts: boolean;
    shipments: boolean;
    planHistory: boolean;
    me: boolean;
  };
  onChangeSelectedContractId: (contractId: string) => void;
}
export const ContractToContent: FC<ContractToToContentProps> = ({ loadings, onChangeSelectedContractId }) => {
  const familyCustomerStore = useFamilyCustomerQueryStore(
    (state: FamilyCustomerQueryState) => state.familyCustomersQuery
  );
  const shipmentsStore = useShipmentsQueryStore((state: ShipmentsQueryState) => state.shipmentsQuery);
  const planHistoryStore = usePlansQueryStore((state: PlansQueryState) => state.plansQuery);
  const meStore = useMeQueryStore((state: MeQueryState) => state.meQuery);
  const selectedContractId = selectedContractStore((state: SelectedContractIdState) => state.selectedContractId);
  const setSelectedContractId = selectedContractStore((state: SelectedContractIdState) => state.set);
  // 契約が提供中 > 契約が停止中 > 契約日時が古いが上
  const sortedFamilyCustomerContractedPlan:
    | GetIndexPagesFamilyCustomerQuery["familyCustomer"]["familyCustomerContractedPlans"]
    | undefined = useMemo(() => {
    if (!familyCustomerStore?.familyCustomer.familyCustomerContractedPlans) {
      return;
    }

    return sortFamilyCustomerContractedPlan(familyCustomerStore.familyCustomer.familyCustomerContractedPlans);
  }, [familyCustomerStore]);

  const isQuitApplicationSubmitted = useMemo(
    () =>
      familyCustomerStore?.familyCustomer.familyCustomerStatus?.uuid ===
      FAMILY_CUSTOMER_STATUS_QUIT_APPLICATION_SUBMITTED,
    [familyCustomerStore?.familyCustomer.familyCustomerStatus?.uuid]
  );

  const resultQuitApplicationQuery = useGetQuitApplicationPagesIndexQuery({
    skip: !familyCustomerStore || !isQuitApplicationSubmitted,
  });

  useEffect(() => {
    setSelectedContractId(sortedFamilyCustomerContractedPlan?.[0]?.uuid ?? undefined);
  }, [sortedFamilyCustomerContractedPlan, setSelectedContractId]);

  useEffect(() => {
    if (selectedContractId) {
      onChangeSelectedContractId(selectedContractId);
    }
  }, [onChangeSelectedContractId, selectedContractId]);

  const latestPlan = useMemo(
    () => getViewableShipmentPlansByContractedPlan(selectedContractId, planHistoryStore?.shipments)?.[0],
    [selectedContractId, planHistoryStore?.shipments]
  );

  return (
    <Stack spacing="1.5rem">
      {loadings.contracts || familyCustomerStore === undefined ? (
        <Skeleton data-testid={SkeletonMenuTestId} py="3rem" />
      ) : (
        <div>
          {familyCustomerStore.familyCustomer.familyCustomerContractedPlans &&
            familyCustomerStore.familyCustomer.familyCustomerContractedPlans.length >= 2 && (
              <Box textAlign="right">
                <Typography color="gray.dark" variant="display6" mb={2} data-testid={LabelContractsCountTestId}>
                  現在のご契約数: {familyCustomerStore.familyCustomer.familyCustomerContractedPlans.length}件
                </Typography>
              </Box>
            )}
          <ContractMenu
            contractPlans={sortedFamilyCustomerContractedPlan}
            initialValue={sortedFamilyCustomerContractedPlan?.[0]}
            onChange={(contractedId) => {
              setSelectedContractId(contractedId);
            }}
          />
        </div>
      )}
      {loadings.shipments ||
      loadings.me ||
      loadings.planHistory ||
      shipmentsStore === undefined ||
      meStore === undefined ? (
        <Skeleton data-testid={SkeletonReturnStatutesTestId} py="8rem" />
      ) : isQuitApplicationSubmitted ? (
        <Box p="1rem" borderRadius="0.5rem" bgColor="mono.light.100" data-testid={BaseCardTestId}>
          <QuitApplicationSubmitted
            nextBillingAt={
              resultQuitApplicationQuery.data?.quitApplication.applicableStatus ===
                QuitApplicationApplicableStatus.Regular &&
              resultQuitApplicationQuery.data.quitApplication.familyCustomerContractedPlan.nextBillingAt
                ? parse(
                    resultQuitApplicationQuery.data.quitApplication.familyCustomerContractedPlan.nextBillingAt,
                    "yyyy-MM-dd HH:mm:ss",
                    new Date()
                  )
                : undefined
            }
          />
        </Box>
      ) : (
        <BaseCard data-testid={BaseCardTestId}>
          <ReturnStatutes
            data={findShipmentsFromContractedPlanUuids(selectedContractId, shipmentsStore.shipments)}
            me={meStore.me}
          />
        </BaseCard>
      )}
      {loadings.planHistory || planHistoryStore === undefined ? (
        <Skeleton data-testid={SkeletonPlanCardTestId} py="8rem" />
      ) : latestPlan === undefined ? (
        <Box bgColor="mono.light.100" p="1rem" borderRadius="0.5rem">
          <PlanEmptyBox />
        </Box>
      ) : (
        <Stack spacing="1.5rem" data-testid={PlanStackTestId}>
          <PlanCardContainer shipmentPlan={latestPlan} />
          <PlanHistoryButton contractedUuid={selectedContractId ?? ""} />
        </Stack>
      )}
    </Stack>
  );
};
