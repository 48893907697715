import { forwardRef } from "@chakra-ui/system";
import {
  MenuItemOption as ChakraMenuItemOption,
  type MenuItemOptionProps as ChakraMenuItemOptionProps,
  Icon,
} from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";
import { css } from "@emotion/react";
import { menuItemOptionTestId } from "./const";

export const MenuItemOption = forwardRef<ChakraMenuItemOptionProps, "button">(({ children, ...props }, ref) => {
  const Component = (
    <ChakraMenuItemOption
      data-testid={menuItemOptionTestId}
      flexDir="row-reverse"
      justifyContent="space-between"
      alignItems="center"
      borderColor="light.main"
      icon={<Icon as={FaCheck} color="primary.main" />}
      css={css`
        .css-usinoq {
          margin-inline-end: 0;
        }
      `}
      _focus={{}}
      _hover={{ boxShadow: "shadow.main", ...props._hover }}
      ref={ref}
      {...props}
    >
      {children}
    </ChakraMenuItemOption>
  );
  return Component;
});
MenuItemOption.id = "MenuItemOption";
