import { type GetPlansPagesIndexQuery } from "@/pages/_index/__gql__/get-plans.gen";
import { getViewableShipmentPlans, type Shipment } from "@/components/fragments/plan";
import { type ShipmentPlan } from "@/components/fragments/plan/type";
import { pagesPath } from "@/pathpida/generated/$path";

export const getViewableShipmentPlansByContractedPlan = (
  selectedContractedPlanUuid: string | undefined,
  shipments: GetPlansPagesIndexQuery["shipments"] | undefined
): ShipmentPlan[] | undefined => {
  return getViewableShipmentPlans(getShipments(selectedContractedPlanUuid, shipments), {
    getPlanDetailHref: (shipmentId) => pagesPath.plans.detail._shipmentId(shipmentId).$url(),
  });
};

const getShipments = (
  selectedContractedPlanUuid: string | undefined,
  shipments: GetPlansPagesIndexQuery["shipments"] | undefined
): Shipment[] | undefined => {
  if (selectedContractedPlanUuid === undefined && shipments === undefined) {
    return undefined;
  }

  return shipments?.find(
    ({ familyCustomerContractedPlanUuid }) => familyCustomerContractedPlanUuid === selectedContractedPlanUuid
  )?.shipments;
};
