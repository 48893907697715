// @ts-nocheck
import * as Types from '../../../gql/generated/graphql.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIndexPagesFamilyCustomerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIndexPagesFamilyCustomerQuery = { __typename?: 'Query', familyCustomer: { __typename?: 'FamilyCustomer', id?: number | null, uuid: string, hasAnsweredSurvey?: boolean | null, familyCustomerStatus?: { __typename?: 'FamilyCustomerStatus', uuid: string, name?: string | null } | null, familyCustomerContractedPlans?: Array<{ __typename?: 'FamilyCustomerContractedPlan', uuid: string, isActive?: boolean | null, contractedAt?: string | null, subscriptionInfo?: { __typename?: 'SubscriptionInfo', name: string } | null, familyChildren?: Array<{ __typename?: 'FamilyChild', birthday?: string | null } | null> | null, paymentType?: { __typename?: 'PaymentType', uuid: string } | null }> | null } };


export const GetIndexPagesFamilyCustomerDocument = gql`
    query getIndexPagesFamilyCustomer {
  familyCustomer {
    id
    uuid
    hasAnsweredSurvey
    familyCustomerStatus {
      uuid
      name
    }
    familyCustomerContractedPlans {
      uuid
      isActive
      contractedAt
      subscriptionInfo {
        name
      }
      familyChildren {
        birthday
      }
      paymentType {
        uuid
      }
    }
  }
}
    `;

/**
 * __useGetIndexPagesFamilyCustomerQuery__
 *
 * To run a query within a React component, call `useGetIndexPagesFamilyCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndexPagesFamilyCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndexPagesFamilyCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIndexPagesFamilyCustomerQuery(baseOptions?: Apollo.QueryHookOptions<GetIndexPagesFamilyCustomerQuery, GetIndexPagesFamilyCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIndexPagesFamilyCustomerQuery, GetIndexPagesFamilyCustomerQueryVariables>(GetIndexPagesFamilyCustomerDocument, options);
      }
export function useGetIndexPagesFamilyCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndexPagesFamilyCustomerQuery, GetIndexPagesFamilyCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIndexPagesFamilyCustomerQuery, GetIndexPagesFamilyCustomerQueryVariables>(GetIndexPagesFamilyCustomerDocument, options);
        }
export function useGetIndexPagesFamilyCustomerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIndexPagesFamilyCustomerQuery, GetIndexPagesFamilyCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIndexPagesFamilyCustomerQuery, GetIndexPagesFamilyCustomerQueryVariables>(GetIndexPagesFamilyCustomerDocument, options);
        }
export type GetIndexPagesFamilyCustomerQueryHookResult = ReturnType<typeof useGetIndexPagesFamilyCustomerQuery>;
export type GetIndexPagesFamilyCustomerLazyQueryHookResult = ReturnType<typeof useGetIndexPagesFamilyCustomerLazyQuery>;
export type GetIndexPagesFamilyCustomerSuspenseQueryHookResult = ReturnType<typeof useGetIndexPagesFamilyCustomerSuspenseQuery>;
export type GetIndexPagesFamilyCustomerQueryResult = Apollo.QueryResult<GetIndexPagesFamilyCustomerQuery, GetIndexPagesFamilyCustomerQueryVariables>;