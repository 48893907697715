import { isBefore, parse } from "date-fns";
import { type GetIndexPagesFamilyCustomerQuery } from "@/pages/_index/__gql__/get-family-customer.gen";

// 契約が提供中 > 契約が停止中 > 契約日時が古いが上 に並べる
export const sortFamilyCustomerContractedPlan = (
  familyCustomerContractedPlans: GetIndexPagesFamilyCustomerQuery["familyCustomer"]["familyCustomerContractedPlans"]
): GetIndexPagesFamilyCustomerQuery["familyCustomer"]["familyCustomerContractedPlans"] => {
  return familyCustomerContractedPlans?.slice().sort((a, b) => {
    // isActive が true のものを先に並べる
    if (a.isActive !== b.isActive) {
      return a.isActive ? -1 : 1;
    }

    // isActive が同じ場合は、createdAt の昇順に並べる
    if (a.contractedAt === undefined || a.contractedAt === null) return 1;
    if (b.contractedAt === undefined || b.contractedAt === null) return -1;
    return isBefore(
      parse(a.contractedAt, "yyyy-MM-dd HH:mm:ss", new Date()),
      parse(b.contractedAt, "yyyy-MM-dd HH:mm:ss", new Date())
    )
      ? -1
      : 1;
  });
};
