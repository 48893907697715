// @ts-nocheck
import * as Types from '../../../gql/generated/graphql.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetQuitApplicationPagesIndexQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetQuitApplicationPagesIndexQuery = { __typename?: 'Query', quitApplication: { __typename?: 'QuitApplication', applicableStatus: Types.QuitApplicationApplicableStatus, familyCustomerContractedPlan: { __typename?: 'QuitApplicationFamilyCustomerContractedPlan', nextBillingAt?: any | null } } };


export const GetQuitApplicationPagesIndexDocument = gql`
    query getQuitApplicationPagesIndex {
  quitApplication {
    applicableStatus
    familyCustomerContractedPlan {
      nextBillingAt
    }
  }
}
    `;

/**
 * __useGetQuitApplicationPagesIndexQuery__
 *
 * To run a query within a React component, call `useGetQuitApplicationPagesIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuitApplicationPagesIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuitApplicationPagesIndexQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuitApplicationPagesIndexQuery(baseOptions?: Apollo.QueryHookOptions<GetQuitApplicationPagesIndexQuery, GetQuitApplicationPagesIndexQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuitApplicationPagesIndexQuery, GetQuitApplicationPagesIndexQueryVariables>(GetQuitApplicationPagesIndexDocument, options);
      }
export function useGetQuitApplicationPagesIndexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuitApplicationPagesIndexQuery, GetQuitApplicationPagesIndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuitApplicationPagesIndexQuery, GetQuitApplicationPagesIndexQueryVariables>(GetQuitApplicationPagesIndexDocument, options);
        }
export function useGetQuitApplicationPagesIndexSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetQuitApplicationPagesIndexQuery, GetQuitApplicationPagesIndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuitApplicationPagesIndexQuery, GetQuitApplicationPagesIndexQueryVariables>(GetQuitApplicationPagesIndexDocument, options);
        }
export type GetQuitApplicationPagesIndexQueryHookResult = ReturnType<typeof useGetQuitApplicationPagesIndexQuery>;
export type GetQuitApplicationPagesIndexLazyQueryHookResult = ReturnType<typeof useGetQuitApplicationPagesIndexLazyQuery>;
export type GetQuitApplicationPagesIndexSuspenseQueryHookResult = ReturnType<typeof useGetQuitApplicationPagesIndexSuspenseQuery>;
export type GetQuitApplicationPagesIndexQueryResult = Apollo.QueryResult<GetQuitApplicationPagesIndexQuery, GetQuitApplicationPagesIndexQueryVariables>;