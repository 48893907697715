import { MenuButton as ChakraMenuButton, type MenuButtonProps as ChakraMenuButtonProps, Flex } from "@chakra-ui/react";
import { Box } from "@/components/essentials/box";
import { useContext, type FC } from "react";
import { MenuContext } from "./context";
import { Icon } from "@/components/essentials/icon";
import { menuButtonLabelTestId, menuButtonTestId } from "./const";
import { Typography } from "@/components/essentials/typography";
import { type AliasColors } from "@/styles/themes/colors/alias";
import { type ColorPalettes } from "@/styles/themes/colors/palettes";

export type MenuButtonProps = Omit<ChakraMenuButtonProps, "color"> & {
  label?: boolean;
  color?: AliasColors | ColorPalettes;
};

export const MenuButton: FC<MenuButtonProps> = ({
  children,
  placeholder: _placeholder,
  color = "gray.main",
  label = false,
  ...rest
}) => {
  const { isOpen } = useContext(MenuContext);

  return (
    <ChakraMenuButton data-testid={menuButtonTestId} p="1rem" bgColor="light.main" as={Box} {...rest}>
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        {children}
        <Flex justifyContent="space-between" alignItems="center">
          {label && (
            <Typography
              color={color}
              variant="display7"
              mr="1"
              data-testid={menuButtonLabelTestId}
              display={["none", "inline"]}
            >
              表示切替
            </Typography>
          )}
          <Icon ml="auto" color={color} as={isOpen ? "angleUp" : "angleDown"} />
        </Flex>
      </Flex>
    </ChakraMenuButton>
  );
};
