import { Icon } from "@/components/essentials/icon";
import { Typography } from "@/components/essentials/typography";
import { HStack, Spacer } from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { type FC } from "react";
import box from "~/assets/images/box.svg";
import { planHistoryButtonTestId } from "./const";
import { pagesPath } from "@/pathpida/generated/$path";

export interface PlanHistoryButtonProps {
  readonly contractedUuid: string;
}

export const PlanHistoryButton: FC<PlanHistoryButtonProps> = (props) => {
  return (
    <HStack
      as={Link}
      href={{ pathname: pagesPath.plans.history.$url().pathname, query: { "contract-id": props.contractedUuid } }}
      p="1.25rem"
      bgColor="mono.light.100"
      borderRadius="0.5rem"
      data-testid={planHistoryButtonTestId}
    >
      <Image src={box} alt="box-icon" width={40} height={40} />
      <Typography variant="display6">おもちゃの履歴はこちら</Typography>
      <Spacer />
      <Icon as="angleRight" />
    </HStack>
  );
};
