interface LocalStorageInterface<T> {
  readonly key: string;
  readonly data: T;
  readonly timestamp: number;
  readonly expires?: number;
}

export const setItem = <T>(input: LocalStorageInterface<T>): void => {
  localStorage.setItem(input.key, JSON.stringify(input));
};

export const getItem = <T>(key: string): T | undefined => {
  const getItemJson = localStorage.getItem(key);
  if (!getItemJson) {
    return undefined;
  }
  const localStorageInterface = JSON.parse(getItemJson) as LocalStorageInterface<T>;
  if (localStorageInterface.expires && Date.now() > localStorageInterface.expires) {
    // 有効期限が切れていれば削除する
    localStorage.removeItem(key);
    return undefined;
  }

  return localStorageInterface.data;
};
