import { type Messaging, getMessaging, getToken, isSupported } from "firebase/messaging";

export const getFCMToken = async (): Promise<string | undefined> => {
  const isSupport = await isSupported();
  if (!isSupport) {
    return;
  }

  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration?.active) {
    return;
  }

  let messaging: Messaging;
  try {
    messaging = getMessaging();
  } catch (e: unknown) {
    return;
  }

  return await getToken(messaging, { vapidKey: process.env.FCM_VAPID_KEY, serviceWorkerRegistration: registration });
};
