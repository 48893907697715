import { Typography } from "@/components/essentials/typography";
import { Flex } from "@chakra-ui/react";
import { Box } from "@/components/essentials/box";
import { useMemo, type FC } from "react";
import Image from "next/image";
import toyboxClosed from "~/assets/images/toybox-closed.svg";
import { type ShipmentStatus } from "@/pages/_index/__components__/statuses/types";
import { type MeQueryState, useMeQueryStore } from "@/pages/_index/__modules__/store";
import { appliedImageTestId, appliedTestId } from "./const";

interface AppliedProps {
  readonly status: Extract<ShipmentStatus, "applied" | "returned">;
}

export const Applied: FC<AppliedProps> = ({ status }) => {
  const meAtomState = useMeQueryStore((state: MeQueryState) => state.meQuery);
  const isVisibleConfirmMessages = useMemo<boolean>(() => {
    if (status === "returned" || meAtomState?.me.isReceiver !== true) {
      return false;
    }

    return true;
  }, [meAtomState, status]);

  return (
    <Flex
      data-testid={appliedTestId}
      flexDir="column"
      gridRowGap={4}
      w="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Image data-testid={appliedImageTestId} src={toyboxClosed} alt="toybox" width={110} height={110} />
      </Box>
      <Typography variant="display1">交換申請を受け付けました</Typography>
      {isVisibleConfirmMessages ? (
        <Typography variant="text4" color="gray.main" mb="0.3125rem">
          申請内容を記載したメールをお送りしました
          <br />
          申請内容につきましてはメールをご確認ください
        </Typography>
      ) : null}
    </Flex>
  );
};
