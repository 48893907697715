import { type FC, useMemo } from "react";
import { Planning } from "./switch-statuses/planning";
import { ReturnInventory } from "./switch-statuses/return-inventory";
import { Applied } from "./switch-statuses/applied";
import { switchStatus } from "./module";
import { type GetIndexPagesShipmentsQuery } from "@/pages/_index/__gql__/get-shipments.gen";
import { type GetIndexPagesMeQuery } from "@/pages/_index/__gql__/get-me.gen";
import { Preview } from "./switch-statuses/preview/Preview";

export interface ReturnStatusProps {
  readonly data: GetIndexPagesShipmentsQuery["shipments"][0]["shipments"] | undefined;
  readonly me: GetIndexPagesMeQuery["me"] | undefined;
}

export const ReturnStatutes: FC<ReturnStatusProps> = ({ data, me }) => {
  const resultLatestShipments = useMemo(() => switchStatus(data, me), [data, me]);

  switch (resultLatestShipments?.status) {
    case "planning":
      return <Planning />;
    case "canPreview":
      return <Preview latestShipment={resultLatestShipments.latestShipment} />;
    case "notReturnInventory":
    case "canReturnInventory":
      return (
        <ReturnInventory status={resultLatestShipments.status} latestShipment={resultLatestShipments.latestShipment} />
      );
    case "applied":
    case "returned":
      return <Applied status={resultLatestShipments.status} />;
    default:
      return <Planning />;
  }
};
