import {
  MenuOptionGroup as ChakraMenuOptionGroup,
  type MenuOptionGroupProps as ChakraMenuOptionGroupProps,
} from "@chakra-ui/react";
import { type FC } from "react";
import { menuOptionGroupTestId } from "./const";

export type MenuOptionGroupProps = ChakraMenuOptionGroupProps;

export const MenuOptionGroup: FC<MenuOptionGroupProps> = ({ children, ...rest }) => {
  return (
    <ChakraMenuOptionGroup data-testid={menuOptionGroupTestId} {...rest}>
      {children}
    </ChakraMenuOptionGroup>
  );
};
