/**
 * ref: https://developers.channel.io/docs/web-quickstart
 * use the SDK through the JavaScript Class for SPA.
 */
declare global {
  interface Window {
    ChannelIO?: IChannelIO;
    ChannelIOInitialized?: boolean;
  }
}

interface IChannelIO {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- It's any For ChannelTalk SDK
  c?: (...args: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- It's any For ChannelTalk SDK
  q?: Array<[methodName: string, ...args: any[]]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- It's any For ChannelTalk SDK
  (...args: any): void;
}

interface BootOption {
  appearance?: string;
  customLauncherSelector?: string;
  hideChannelButtonOnBoot?: boolean;
  hidePopup?: boolean;
  language?: string;
  memberHash?: string;
  memberId?: string;
  mobileMessengerMode?: string;
  pluginKey: string;
  profile?: Profile;
  trackDefaultEvent?: boolean;
  trackUtmSource?: boolean;
  unsubscribe?: boolean;
  unsubscribeEmail?: boolean;
  unsubscribeTexting?: boolean;
  zIndex?: number;
}

type Callback = (error: Error | null, user: CallbackUser | null) => void;

interface CallbackUser {
  alert: number;
  avatarUrl: string;
  id: string;
  language: string;
  memberId: string;
  name?: string;
  profile?: Profile | null;
  tags?: string[] | null;
  unsubscribeEmail: boolean;
  unsubscribeTexting: boolean;
}

interface UpdateUserInfo {
  language?: string;
  profile?: Profile | null;
  profileOnce?: Profile;
  tags?: string[] | null;
  unsubscribeEmail?: boolean;
  unsubscribeTexting?: boolean;
}

type Profile = Record<string, string | number | boolean | null>;

interface FollowUpProfile {
  name?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
}

type EventProperty = Record<string, string | number | boolean | null>;

type Appearance = "light" | "dark" | "system" | null;

class ChannelService {
  private static instance: ChannelService | undefined;

  private constructor() {
    this.loadScript();
  }

  public static getInstance(): ChannelService {
    if (!ChannelService.instance) {
      ChannelService.instance = new ChannelService();
    }

    return ChannelService.instance;
  }

  loadScript(): void {
    (function (): void {
      const w = window;
      if (w.ChannelIO) {
        w.console.error("ChannelIO script included twice.");
        return;
      }
      const ch = function (): void {
        ch.c(arguments);
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- It's any For ChannelTalk SDK
      ch.q = [] as any[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- It's any For ChannelTalk SDK
      ch.c = function (args: any) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;

      function l(): void {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
        const x = document.getElementsByTagName("script")[0] as HTMLScriptElement;
        if (x.parentNode) {
          x.parentNode.insertBefore(s, x);
        }
      }

      if (document.readyState === "complete") {
        l();
      } else {
        w.addEventListener("DOMContentLoaded", l);
        w.addEventListener("load", l);
      }
    })();
  }

  boot(option: BootOption, callback?: Callback): void {
    window.ChannelIO?.("boot", option, callback);
  }

  shutdown(): void {
    window.ChannelIO?.("shutdown");
  }

  showMessenger(): void {
    window.ChannelIO?.("showMessenger");
  }

  hideMessenger(): void {
    window.ChannelIO?.("hideMessenger");
  }

  openChat(chatId?: string | number, message?: string): void {
    window.ChannelIO?.("openChat", chatId, message);
  }

  track(eventName: string, eventProperty?: EventProperty): void {
    window.ChannelIO?.("track", eventName, eventProperty);
  }

  onShowMessenger(callback: () => void): void {
    window.ChannelIO?.("onShowMessenger", callback);
  }

  onHideMessenger(callback: () => void): void {
    window.ChannelIO?.("onHideMessenger", callback);
  }

  onBadgeChanged(callback: (alert: number) => void): void {
    window.ChannelIO?.("onBadgeChanged", callback);
  }

  onChatCreated(callback: () => void): void {
    window.ChannelIO?.("onChatCreated", callback);
  }

  onFollowUpChanged(callback: (profile: FollowUpProfile) => void): void {
    window.ChannelIO?.("onFollowUpChanged", callback);
  }

  onUrlClicked(callback: (url: string) => void): void {
    window.ChannelIO?.("onUrlClicked", callback);
  }

  clearCallbacks(): void {
    window.ChannelIO?.("clearCallbacks");
  }

  updateUser(userInfo: UpdateUserInfo, callback?: Callback): void {
    window.ChannelIO?.("updateUser", userInfo, callback);
  }

  addTags(tags: string[], callback?: Callback): void {
    window.ChannelIO?.("addTags", tags, callback);
  }

  removeTags(tags: string[], callback?: Callback): void {
    window.ChannelIO?.("removeTags", tags, callback);
  }

  setPage(page: string): void {
    window.ChannelIO?.("setPage", page);
  }

  resetPage(): void {
    window.ChannelIO?.("resetPage");
  }

  showChannelButton(): void {
    window.ChannelIO?.("showChannelButton");
  }

  hideChannelButton(): void {
    window.ChannelIO?.("hideChannelButton");
  }

  setAppearance(appearance: Appearance): void {
    window.ChannelIO?.("setAppearance", appearance);
  }
}

export const bootChannelTalk = (memberId: string, email: string): void => {
  if (typeof process.env.CHANNEL_TALK_PLUGIN_KEY !== "string") {
    throw new Error("env type is invalid. CHANNEL_TALK_PLUGIN_KEY must ne string");
  }
  const channelService = ChannelService.getInstance();

  channelService.boot({
    pluginKey: process.env.CHANNEL_TALK_PLUGIN_KEY,
    memberId,
    profile: {
      email,
    },
  });
};
