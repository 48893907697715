import { useEffect } from "react";
import { type PlansQueryState, usePlansQueryStore } from "@/pages/_index/__modules__/store";
import { useGetPlansPagesIndexQuery } from "@/pages/_index/__gql__/get-plans.gen";

export const useGetPlansPagesIndexQueryAtom = (
  options: Parameters<typeof useGetPlansPagesIndexQuery>[0]
): ReturnType<typeof useGetPlansPagesIndexQuery> => {
  const result = useGetPlansPagesIndexQuery(options);
  const setPlansAtom = usePlansQueryStore((state: PlansQueryState) => state.set);

  useEffect(() => {
    if (result.data !== undefined) {
      setPlansAtom(result.data);
    }
  }, [result.data, setPlansAtom]);
  return result;
};
