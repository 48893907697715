import { useMemo, type FC } from "react";
import Link from "next/link";
import { type ShipmentStatus } from "@/pages/_index/__components__/statuses/types";
import { Typography } from "@/components/essentials/typography";
import { Button } from "@/components/essentials/button";
import { type ReturnStatusProps } from "@/pages/_index/__components__/statuses/ReturnStatuses";
import { Center, Flex, Image } from "@chakra-ui/react";
import { Box } from "@/components/essentials/box";
import toyboxClosed from "~/assets/images/toybox-closed.svg";
import toyboxOpen from "~/assets/images/toybox-open.svg";
import { formatAsiaTokyoDateToString } from "@/modules/utils/date";
import { pagesPath } from "@/pathpida/generated/$path";
import {
  type MeQueryState,
  useMeQueryStore,
  useFamilyCustomerQueryStore,
  type FamilyCustomerQueryState,
  selectedContractStore,
  type SelectedContractIdState,
} from "@/pages/_index/__modules__/store";
import {
  returnInventoryButtonTestId,
  returnInventoryCanReturnInventoryTestId,
  returnInventoryImageTestId,
  returnInventoryNotReturnInventoryTestId,
  returnInventoryTestId,
} from "./const";
import { useGetIsPurchasedShipmentToysPagesPurchaseShipmentIdQuery } from "@/pages/purchase/[shipmentId]/__gql__/get-is-purchased-shipment-toys.gen";
import { type PaymentType, getPaymentTypeFromContractedPlansAndContractId } from "@/modules/utils/payment-type";

interface ReturnInvenotoryProps {
  readonly status: Extract<ShipmentStatus, "notReturnInventory" | "canReturnInventory">;
  readonly latestShipment: NonNullable<ReturnStatusProps["data"]>[0] | undefined;
}

export const ReturnInventory: FC<ReturnInvenotoryProps> = ({ status, latestShipment }) => {
  const resultIsPurchasedShipmentToys = useGetIsPurchasedShipmentToysPagesPurchaseShipmentIdQuery({
    skip: !latestShipment,
    variables: { shipmentId: latestShipment?.uuid ?? "" },
  });

  const meAtomState = useMeQueryStore((state: MeQueryState) => state.meQuery);
  const familyCustomerStore = useFamilyCustomerQueryStore(
    (state: FamilyCustomerQueryState) => state.familyCustomersQuery
  );
  const selectedContractId = selectedContractStore((state: SelectedContractIdState) => state.selectedContractId);

  const paymentType: PaymentType | undefined = useMemo(() => {
    if (!familyCustomerStore || !selectedContractId) return;

    return getPaymentTypeFromContractedPlansAndContractId(
      familyCustomerStore.familyCustomer.familyCustomerContractedPlans,
      selectedContractId
    );
  }, [familyCustomerStore, selectedContractId]);

  return (
    <Flex
      data-testid={returnInventoryTestId}
      flexDir="column"
      gridRowGap={4}
      w="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Image
          data-testid={returnInventoryImageTestId}
          src={status === "notReturnInventory" ? toyboxClosed : toyboxOpen}
          alt="toybox"
          width={110}
          height={110}
        />
      </Box>
      {status === "notReturnInventory" && (
        <Box data-testid={returnInventoryNotReturnInventoryTestId} textAlign="center">
          <Typography variant="text4" color="gray.main" mb="0.3125rem">
            次回の交換申請可能日
          </Typography>
          <Typography variant="display1">
            {formatAsiaTokyoDateToString(latestShipment?.nextAvailableReturnInventoryDate, "yyyy年MM月dd日")}
          </Typography>
        </Box>
      )}
      {status === "canReturnInventory" && (
        <Box data-testid={returnInventoryCanReturnInventoryTestId} textAlign="center">
          <Typography variant="display1">おもちゃ交換の申請ができます</Typography>
        </Box>
      )}
      <Center h="3rem">
        {meAtomState?.me.isReceiver && status === "canReturnInventory" && (
          <Button
            as={Link}
            href={
              resultIsPurchasedShipmentToys.data?.isPurchasedShipmentToys ||
              !meAtomState.me.isContractor ||
              paymentType !== "stripe"
                ? pagesPath.exchange._shipmentId(latestShipment?.uuid ?? "").$url()
                : pagesPath.purchase._shipmentId(latestShipment?.uuid ?? "").$url()
            }
            data-testid={returnInventoryButtonTestId}
            variant="solid"
          >
            交換申請する
          </Button>
        )}
        {meAtomState?.me.isReceiver && status === "notReturnInventory" && (
          <Button data-testid={returnInventoryButtonTestId} disabled variant="solid">
            交換申請する
          </Button>
        )}
      </Center>
    </Flex>
  );
};
