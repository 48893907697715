import { Icon } from "@/components/essentials/icon";
import { Typography } from "@/components/essentials/typography";
import { Center, HStack, Spacer } from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { type FC } from "react";
import { externalLinkButtonTestId } from "./const";

export interface ExternalLinkButtonProps {
  readonly icon: string;
  readonly linkText: string;
  readonly href: string;
}

export const ExternalLinkButton: FC<ExternalLinkButtonProps> = ({ icon, linkText, href }) => {
  return (
    <HStack
      as={Link}
      href={href}
      p="1.25rem"
      bgColor="mono.light.100"
      borderRadius="0.5rem"
      data-testid={externalLinkButtonTestId}
      target="_blank"
      rel="noopener norefferer"
    >
      <Center width="40px" height="40px">
        <Image src={icon} alt="box-icon" width={40} height={40} />
      </Center>
      <Typography variant="display6">{linkText}</Typography>
      <Spacer />
      <Icon as="angleRight" />
    </HStack>
  );
};
