import { Typography } from "@/components/essentials/typography";
import { Flex } from "@chakra-ui/react";
import { Box } from "@/components/essentials/box";
import { type FC } from "react";
import Image from "next/image";
import toyboxOpen from "~/assets/images/toybox-open.svg";
import { planningImageTestId, planningTestId, planningTypographyTestId } from "./const";

export const Planning: FC = () => {
  return (
    <Flex
      data-testid={planningTestId}
      flexDir="column"
      gridRowGap={2}
      w="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Image data-testid={planningImageTestId} src={toyboxOpen} alt="toybox" width={110} height={110} />
      </Box>
      <Box data-testid={planningTypographyTestId} textAlign="center">
        <Typography variant="text4" color="gray.main" mb="0.3125rem">
          おもちゃの到着まで少々お待ち下さい
        </Typography>
        <Typography variant="display1">おもちゃの準備をしています</Typography>
      </Box>
    </Flex>
  );
};
