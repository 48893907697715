import { getFCMToken } from "@/modules/utils/firebase";
import { Analytics } from "@aws-amplify/analytics";
import { setItem, getItem } from "./local-storage";
import { v4 as uuidv4 } from "uuid";
import { ChannelType } from "@aws-sdk/client-pinpoint";
import { datadogLogs } from "@datadog/browser-logs";

export interface User {
  readonly id: string;
  readonly userAttributes?: UserAttributes;
}
export interface UserAttributes {
  readonly mail?: string;
  readonly membershipType: MembershipType;
}
type MembershipType = "LEAD" | "CUSTOMER";

const cachePrefix = `aws-cacheAWSPinpoint_${process.env.AA_AWS_MOBILE_ANALYTICS_APP_ID ?? ""}`;

export const mobiletargetingUpdateEndpoint = async (user: Partial<User>): Promise<void> => {
  try {
    const currentToken = await getFCMToken();
    if (!currentToken) {
      return;
    }
    const cacheKey = `${cachePrefix}_${ChannelType.GCM.valueOf()}`;
    void updateEndpoint(cacheKey, currentToken, user, ChannelType.GCM);
  } catch (e: unknown) {}
};

export const mailTargetingUpdateEndpoint = async (mail: string, user: Partial<User>): Promise<void> => {
  try {
    const cacheKey = `${cachePrefix}_${ChannelType.EMAIL.valueOf()}`;
    void updateEndpoint(cacheKey, mail, user, ChannelType.EMAIL);
  } catch (e: unknown) {}
};

const updateEndpoint = async (
  cacheKey: string,
  address: string,
  user: Partial<User>,
  channelType: ChannelType
): Promise<void> => {
  const endpointId = getItem(cacheKey) as string;
  if (endpointId) {
    Analytics.configure({
      Analytics: {
        AWSPinpoint: {
          endpointId,
        },
      },
    });
  } else {
    const amazonPinpointUuid = uuidv4();
    setItem({
      key: cacheKey,
      data: amazonPinpointUuid,
      timestamp: Date.now(),
    });
    Analytics.configure({
      Analytics: {
        AWSPinpoint: {
          endpointId: amazonPinpointUuid,
        },
      },
    });
  }
  let userAttributes;
  if (user.userAttributes !== undefined) {
    userAttributes = objectToArray(user.userAttributes);
  }

  try {
    await Analytics.updateEndpoint({
      address,
      channelType: channelType.valueOf(),
      optOut: "NONE",
      userId: user.id,
      userAttributes,
    });
  } catch (e: unknown) {
    datadogLogs.logger.warn("Amazon Pinpoint update endpoint api is failure.", e as object);
  }
};
const objectToArray = (obj: UserAttributes): Record<string, UserAttributes[]> =>
  Object.entries(obj).reduce<Record<string, UserAttributes[]>>((acc, [key, value]) => {
    // eslint-disable-next-line security/detect-object-injection -- Warning level
    acc[key] = Array.isArray(value) ? value : [value];
    return acc;
  }, {});
