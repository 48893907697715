import { PAYMENT_TYPE_STRIPE } from "@/configs/uuids/payment-type/paymentType";
import { type FamilyCustomerContractedPlan } from "@/gql/generated/graphql.gen";

export type PaymentType = "stripe" | "other";

export type partialContractedPlanForCheckPaymentType = Pick<FamilyCustomerContractedPlan, "uuid" | "paymentType">;

export const getPaymentTypeFromContractedPlansAndContractId = (
  contractedPlans: partialContractedPlanForCheckPaymentType[] | null | undefined,
  contractId: string
): PaymentType => {
  const paymentTypeUuid = contractedPlans?.find((plan) => plan.uuid === contractId)?.paymentType?.uuid;

  switch (paymentTypeUuid) {
    case PAYMENT_TYPE_STRIPE:
      return "stripe";
    default:
      return "other";
  }
};
