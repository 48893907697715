import { Box } from "@/components/essentials/box";
import { Icon } from "@/components/essentials/icon";
import { Typography } from "@/components/essentials/typography";
import { type AliasColors } from "@/styles/themes/colors/alias";
import { type ColorPalettes } from "@/styles/themes/colors/palettes";
import { Center, HStack, Spacer } from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { type FC } from "react";

export interface BannerProps {
  readonly icon: string;
  readonly title: string;
  readonly description: string;
  readonly descriptionColor: AliasColors | ColorPalettes | undefined;
  readonly href: string;
  readonly showBorder?: boolean;
  readonly testid?: string;
}

export const Banner: FC<BannerProps> = ({ icon, title, description, descriptionColor, href, showBorder, testid }) => {
  return (
    <HStack
      as={Link}
      href={href}
      p="1rem"
      bgColor="mono.light.100"
      borderRadius="0.5rem"
      target="_blank"
      rel="noopener norefferer"
      data-testid={testid}
    >
      <Center
        width="70px"
        height="70px"
        borderColor="#aeaeae"
        borderWidth={showBorder ? 0.1 : 0}
        borderRadius={showBorder ? "8px" : 0}
        overflow={showBorder ? "hidden" : "visible"}
      >
        <Image src={icon} alt="box-icon" width={70} height={70} />
      </Center>
      <Box ml="0.75rem">
        <Typography variant="text4-bold" color={descriptionColor}>
          {description}
        </Typography>
        <Typography variant="display6" mt="2px">
          {title}
        </Typography>
      </Box>
      <Spacer />
      <Icon as="angleRight" />
    </HStack>
  );
};
