// @ts-nocheck
import * as Types from '../../../../../gql/generated/graphql.gen';

import { gql } from '@apollo/client';
export type PlanCardContainerFragmentFieldsFragment = { __typename?: 'Shipment', uuid: string, shippedAt?: string | null, shipmentType?: { __typename?: 'ShipmentType', uuid: string } | null, shipmentStatus?: { __typename?: 'ShipmentStatus', uuid: string } | null, shipmentToys: Array<{ __typename?: 'ShipmentToy', uuid: string, toy: { __typename?: 'Toy', toyImages: Array<{ __typename?: 'ToyImage', path: string, thumbnailImage: { __typename?: 'ThumbnailImage', pathFixed256: string } }> } }>, additionalShipments: Array<{ __typename?: 'Shipment', uuid: string, shippedAt?: string | null, shipmentStatus?: { __typename?: 'ShipmentStatus', uuid: string } | null, shipmentToys: Array<{ __typename?: 'ShipmentToy', uuid: string, toy: { __typename?: 'Toy', toyImages: Array<{ __typename?: 'ToyImage', path: string, thumbnailImage: { __typename?: 'ThumbnailImage', pathFixed256: string } }> } }> }> };

export const PlanCardContainerFragmentFieldsFragmentDoc = gql`
    fragment PlanCardContainerFragmentFields on Shipment {
  uuid
  shippedAt
  shipmentType {
    uuid
  }
  shipmentStatus {
    uuid
  }
  shipmentToys {
    uuid
    toy {
      toyImages {
        path
        thumbnailImage {
          pathFixed256
        }
      }
    }
  }
  additionalShipments {
    uuid
    shippedAt
    shipmentStatus {
      uuid
    }
    shipmentToys {
      uuid
      toy {
        toyImages {
          path
          thumbnailImage {
            pathFixed256
          }
        }
      }
    }
  }
}
    `;