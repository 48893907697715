import { useGetIndexPagesFamilyCustomerQuery } from "@/pages/_index/__gql__/get-family-customer.gen";
import { useEffect } from "react";
import { type FamilyCustomerQueryState, useFamilyCustomerQueryStore } from "@/pages/_index/__modules__/store";

export const useGetIndexPagesFamilyCustomerQueryAtom = (
  options?: Parameters<typeof useGetIndexPagesFamilyCustomerQuery>[0]
): ReturnType<typeof useGetIndexPagesFamilyCustomerQuery> => {
  const result = useGetIndexPagesFamilyCustomerQuery(options);
  const setFamilyCustomerAtomState = useFamilyCustomerQueryStore((state: FamilyCustomerQueryState) => state.set);
  useEffect(() => {
    if (result.data !== undefined) {
      setFamilyCustomerAtomState(result.data);
    }
  }, [result.data, setFamilyCustomerAtomState]);
  return result;
};
