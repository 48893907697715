import { type ReactNode, type FC, useState } from "react";
import { Menu as ChakraMenu, type MenuProps as ChakraMenuProps } from "@chakra-ui/react";
import { MenuContext } from "./context";
import { menuTestId } from "./const";

export interface MenuProps extends Omit<ChakraMenuProps, "size"> {
  readonly children: ReactNode;
}

export const Menu: FC<MenuProps> = ({ children, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ChakraMenu
      data-testid={menuTestId}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      {...rest}
    >
      <MenuContext.Provider
        value={{
          isOpen,
        }}
      >
        {children}
      </MenuContext.Provider>
    </ChakraMenu>
  );
};
