import { type GetIndexPagesFamilyCustomerQuery } from "@/pages/_index/__gql__/get-family-customer.gen";
import { type GetIndexPagesShipmentsQuery } from "@/pages/_index/__gql__/get-shipments.gen";

export const extractContractedPlanUuids = (
  contractedPlans: GetIndexPagesFamilyCustomerQuery["familyCustomer"]["familyCustomerContractedPlans"],
  option?: { first?: boolean }
): string[] | undefined => {
  const uuids = contractedPlans?.map(({ uuid }) => uuid);

  if (option?.first) {
    if (uuids?.[0] === undefined) {
      return undefined;
    }
    return [uuids[0]];
  }
  return uuids;
};

export const findShipmentsFromContractedPlanUuids = (
  selectedContractedPlanUuid: string | undefined,
  shipments: GetIndexPagesShipmentsQuery["shipments"] | undefined
): GetIndexPagesShipmentsQuery["shipments"][0]["shipments"] | undefined => {
  if (selectedContractedPlanUuid === undefined && shipments === undefined) {
    return undefined;
  } else
    return shipments?.find(
      ({ familyCustomerContractedPlanUuid }) => familyCustomerContractedPlanUuid === selectedContractedPlanUuid
    )?.shipments;
};
