import { useGetIndexPagesShipmentsQuery } from "@/pages/_index/__gql__/get-shipments.gen";
import { useEffect } from "react";
import { type ShipmentsQueryState, useShipmentsQueryStore } from "@/pages/_index/__modules__/store";

export const useGetIndexPagesShipmentsQueryAtom = (
  options: Parameters<typeof useGetIndexPagesShipmentsQuery>[0]
): ReturnType<typeof useGetIndexPagesShipmentsQuery> => {
  const result = useGetIndexPagesShipmentsQuery(options);
  const setShipmentsAtom = useShipmentsQueryStore((state: ShipmentsQueryState) => state.set);

  useEffect(() => {
    if (result.data !== undefined) {
      setShipmentsAtom(result.data);
    }
  }, [result.data, setShipmentsAtom]);
  return result;
};
