// @ts-nocheck
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: { input: any; output: any; }
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: { input: any; output: any; }
  /** uuid format. */
  UUID: { input: any; output: any; }
};

export type AccountRole = {
  __typename?: 'AccountRole';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Address = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  buildingName?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mail: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  prefectureCode: Scalars['Int']['input'];
};

export type Child = {
  birthDate: Scalars['String']['input'];
  sexCode: Scalars['Int']['input'];
};

export type ContractedPlan = {
  __typename?: 'ContractedPlan';
  madrasContractedPlanUuid: Scalars['ID']['output'];
  roles: Array<AccountRole>;
};

export type CouponStatus = {
  __typename?: 'CouponStatus';
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type CreateCustomerInput = {
  child: Child;
  customerAddress: Address;
  desiredDeliveryMethodTimeUuid?: InputMaybe<Scalars['String']['input']>;
  priceUuid: Scalars['String']['input'];
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  shippingAddress: Address;
  specialNote?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomerSlotInput = {
  paymentServiceProvider: Scalars['String']['input'];
};

export type CreateCustomerSlotResponse = {
  __typename?: 'CreateCustomerSlotResponse';
  clientSecret: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type CreateFamilyCustomerAddressInput = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  buildingName?: InputMaybe<Scalars['String']['input']>;
  deliveryMethodTime?: InputMaybe<FamilyCustomerAddressDeliveryMethodTimeInput>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  pref: Scalars['String']['input'];
  tel: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type Customer = {
  __typename?: 'Customer';
  madrasCustomerUuid: Scalars['ID']['output'];
};

export type DeliveryMethodTime = {
  __typename?: 'DeliveryMethodTime';
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type Deposit = {
  __typename?: 'Deposit';
  cardInfoUpdateUrl?: Maybe<Scalars['String']['output']>;
};

export type ExchangePreShippingInput = {
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=2063%3A23008 */
  shipmentToys: Array<ExchangePreShippingShipmentToyInput>;
};

export type ExchangePreShippingShipmentToyInput = {
  /**
   * Madras: shipment_toys.uuid, Figma: (none)
   * memo: toys.uuid にするか迷ったが、shipment_toys.uuid のほうが紐付け情報が多く今後の仕様変更に強いと考えた
   */
  shipmentToyUuid: Scalars['ID']['input'];
  /** Madras: (まだない), Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=2063%3A23008 */
  status: ShipmentToyCustomerPreShippingStatus;
  toyMakerName: Scalars['String']['input'];
  toyName: Scalars['String']['input'];
};

export type FamilyAccount = {
  __typename?: 'FamilyAccount';
  contractedPlans: Array<ContractedPlan>;
  /** 顧客としての情報 */
  customer: Customer;
  /** 契約者権限を持っているかどうか */
  isContractor: Scalars['Boolean']['output'];
  /** 二段階認証設定が有効かどうか */
  isMfaActive: Scalars['Boolean']['output'];
  /** 配送先権限を持っているかどうか */
  isReceiver: Scalars['Boolean']['output'];
  mail: Scalars['String']['output'];
  /** 紐付いている「おもちゃ買取クーポン」の一覧 */
  toyCoupons: Array<ToyCoupon>;
  uuid: Scalars['ID']['output'];
};

export type FamilyChild = {
  __typename?: 'FamilyChild';
  birthday?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type FamilyCustomer = {
  __typename?: 'FamilyCustomer';
  familyChildren?: Maybe<Array<Maybe<FamilyChild>>>;
  familyCustomerAddresses?: Maybe<Array<FamilyCustomerAddress>>;
  familyCustomerContractedPlans?: Maybe<Array<FamilyCustomerContractedPlan>>;
  familyCustomerStatus?: Maybe<FamilyCustomerStatus>;
  familyCustomerType?: Maybe<FamilyCustomerType>;
  firstName?: Maybe<Scalars['String']['output']>;
  firstNameOfPronunciation?: Maybe<Scalars['String']['output']>;
  hasAnsweredSurvey?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastNameOfPronunciation?: Maybe<Scalars['String']['output']>;
  mail?: Maybe<Scalars['String']['output']>;
  primaryFamilyCustomerAddress?: Maybe<FamilyCustomerAddress>;
  primaryFamilyCustomerBillingAddress?: Maybe<FamilyCustomerBillingAddress>;
  tel?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type FamilyCustomerAddress = {
  __typename?: 'FamilyCustomerAddress';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deliveryMethodTime?: Maybe<DeliveryMethodTime>;
  familyCustomer?: Maybe<FamilyCustomer>;
  familyHouseType?: Maybe<FamilyHouseType>;
  firstName?: Maybe<Scalars['String']['output']>;
  firstNameOfPronunciation?: Maybe<Scalars['String']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastNameOfPronunciation?: Maybe<Scalars['String']['output']>;
  mail?: Maybe<Scalars['String']['output']>;
  pref?: Maybe<Scalars['String']['output']>;
  tel?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FamilyCustomerAddressDeliveryMethodTimeInput = {
  uuid: Scalars['ID']['input'];
};

export type FamilyCustomerAddressFamilyCustomerInput = {
  uuid: Scalars['ID']['input'];
};

export type FamilyCustomerBillingAddress = {
  __typename?: 'FamilyCustomerBillingAddress';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  familyCustomer?: Maybe<FamilyCustomer>;
  firstName?: Maybe<Scalars['String']['output']>;
  firstNameOfPronunciation?: Maybe<Scalars['String']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastNameOfPronunciation?: Maybe<Scalars['String']['output']>;
  pref?: Maybe<Scalars['String']['output']>;
  tel?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FamilyCustomerContractedPlan = {
  __typename?: 'FamilyCustomerContractedPlan';
  contractedAt?: Maybe<Scalars['String']['output']>;
  familyChildren?: Maybe<Array<Maybe<FamilyChild>>>;
  familyCustomer?: Maybe<FamilyCustomer>;
  familyCustomerBillingAddress?: Maybe<FamilyCustomerBillingAddress>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  paymentType?: Maybe<PaymentType>;
  /** サブスクリプション。予約語と被るので適当に info と付けている */
  subscriptionInfo?: Maybe<SubscriptionInfo>;
  uuid: Scalars['ID']['output'];
};

export type FamilyCustomerStatus = {
  __typename?: 'FamilyCustomerStatus';
  name?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type FamilyCustomerType = {
  __typename?: 'FamilyCustomerType';
  name?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type FamilyHouseType = {
  __typename?: 'FamilyHouseType';
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type GenerateMfaActivateKeyResult = {
  __typename?: 'GenerateMfaActivateKeyResult';
  otpauthUri: Scalars['String']['output'];
  secretKey: Scalars['String']['output'];
};

export enum GraduateServiceCompanies {
  /** "ドラキッズ */
  Dorakid = 'DORAKID',
  /** "ディズニー英会話 */
  Dwe = 'DWE',
  /** "ミキハウス */
  Mikihouse = 'MIKIHOUSE',
  /** 天神 */
  Tao = 'TAO',
  /** ワンダーボックス */
  Wonderbox = 'WONDERBOX'
}

/** ヘルスチェックAPIのレスポンス */
export type Health = {
  __typename?: 'Health';
  /** Gitのコミットハッシュ（取得できない場合はnull） */
  hash?: Maybe<Scalars['String']['output']>;
};

export type IndividualToy = {
  __typename?: 'IndividualToy';
  uuid: Scalars['ID']['output'];
};

export type LeadChild = {
  birthday?: InputMaybe<Scalars['Date']['input']>;
};

export type LeadInput = {
  child?: InputMaybe<LeadChild>;
  leadSource: LeadSource;
  mail: Scalars['String']['input'];
  promotionCode?: InputMaybe<Scalars['String']['input']>;
};

export enum LeadSource {
  /** キャンペーンコードなしの通常申し込み */
  Basic = 'BASIC',
  /** キャンペーンコードありの申し込み */
  Campaign = 'CAMPAIGN',
  /** チャネルトーク */
  ChannelTalk = 'CHANNEL_TALK',
  /** おもちゃ診断 */
  ToyDiagnose = 'TOY_DIAGNOSE',
  /** Zoho Survey */
  ZohoSurvey = 'ZOHO_SURVEY'
}

export type LivingEnvironment = {
  uuid: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};

export type LivingEnvironmentInput = {
  uuid: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** 二段階認証設定を有効化しリカバリーコードを取得する。パスワード検証 API 実行後の一定時間内を前提とする */
  activateMfa: Array<Scalars['String']['output']>;
  /** メールアドレス変更用のメールを送信する */
  applyChangeMail: Scalars['Boolean']['output'];
  /** 二段階認証を行う。サインイン API 実行後の一定時間内を前提とする */
  authorizeMfaCode: Scalars['Boolean']['output'];
  /** リカバリーコードでの認証を行う。使用したリカバリーコードは破棄される。サインイン API 実行後の一定時間内を前提とする */
  authorizeRecoveryCode: Scalars['Boolean']['output'];
  /** 申込み完了 */
  completePlanApplication: Scalars['Boolean']['output'];
  /** 顧客情報を設定する */
  createCustomer: Scalars['Boolean']['output'];
  /** 顧客を作成する(顧客情報は入力しない) */
  createCustomerSlot: CreateCustomerSlotResponse;
  /** お届先情報を新規作成し、規定のお届先住所に設定する */
  createFamilyCustomerAddress: Scalars['Boolean']['output'];
  createLead: Scalars['Boolean']['output'];
  /** 二段階認証設定を無効化する。既存のリカバリーコードは破棄される。パスワード検証 API 実行後の一定時間内を前提とする */
  deactivateMfa: Scalars['Boolean']['output'];
  /** お届先情報を削除する */
  deleteFamilyCustomerAddress: Scalars['Boolean']['output'];
  /** メールアドレスを変更する */
  doChangeMail: Scalars['Boolean']['output'];
  /** パスワードを変更する。パスワード検証 API 実行後の一定時間内を前提とする */
  doRenewPassword: Scalars['Boolean']['output'];
  /** パスワードをリセットする */
  doResetPassword: Scalars['Boolean']['output'];
  /** 交換申請を行い、キャッシュの一時保存内容も更新する。正常終了後、キャッシュの一時保存内容は論理削除する */
  exchangeInventory: Scalars['Boolean']['output'];
  /** 発送前交換申請をする */
  exchangePreShipping: Scalars['Boolean']['output'];
  /** 二段階認証設定のためのアクティベートキーを取得する。パスワード検証 API 実行後の一定時間内を前提とする */
  generateMfaActivateKey: GenerateMfaActivateKeyResult;
  /** 決済プラットフォームのカスタマーURLを発行する */
  generatePaymentCustomerPortalSession: Scalars['String']['output'];
  /** 手持ちおもちゃアンケートの回答を設定する */
  postQuestionnaire: Scalars['Boolean']['output'];
  /** 手持ちおもちゃアンケートの回答を設定する。サインインが必要 */
  postQuestionnaireWithSignIn: Scalars['Boolean']['output'];
  /** 退会申請を行う */
  postQuitApplication: Scalars['Boolean']['output'];
  /** 配送済のおもちゃを買い取る */
  purchaseShipmentToys: Scalars['Boolean']['output'];
  /** 二段階認証用のリカバリーコードを再生成し取得する。既存のリカバリーコードは破棄される。パスワード検証 API 実行後の一定時間内を前提とする */
  regenerateRecoveryCode: Array<Scalars['String']['output']>;
  /** 新規登録する */
  registerFamilyAccount: Scalars['Boolean']['output'];
  /** 新規登録用のメールを送信する */
  requestRegistrationMail: Scalars['Boolean']['output'];
  /** パスワード再設定用のメールを送信する */
  resetPassword: Scalars['Boolean']['output'];
  /** 退会顧客向けの資料送付 */
  sendGraduates: Scalars['Boolean']['output'];
  /** 二段階認証が設定されていない場合はサインインする。二段階認証が設定されている場合は「パスワード認証は済」の状態にするのみで、二段階認証を行うとサインインできる */
  signIn: SignInResult;
  /** サインアウトする */
  signOut: Scalars['Boolean']['output'];
  /** 交換申請フォームの入力内容をキャッシュに一時保存する */
  storeExchangeInventory: Scalars['Boolean']['output'];
  /** 請求先情報を更新し、規定の請求先情報に設定する */
  updateCustomerBillingInfo: Scalars['Boolean']['output'];
  /** お届先情報を更新し、規定のお届先住所に設定する */
  updateFamilyCustomerAddress: Scalars['Boolean']['output'];
  /** 既定の住所を設定する */
  updatePrimaryAddress: Scalars['Boolean']['output'];
  /** パスワードの検証を行い、一定時間一部 API (パスワード変更など)の実行を許容する */
  verifyPassword: Scalars['Boolean']['output'];
};


export type MutationActivateMfaArgs = {
  mfaCode: Scalars['String']['input'];
};


export type MutationApplyChangeMailArgs = {
  newMail: Scalars['String']['input'];
};


export type MutationAuthorizeMfaCodeArgs = {
  mfaCode: Scalars['String']['input'];
};


export type MutationAuthorizeRecoveryCodeArgs = {
  recoveryCode: Scalars['String']['input'];
};


export type MutationCompletePlanApplicationArgs = {
  customerId: Scalars['String']['input'];
};


export type MutationCreateCustomerArgs = {
  customerId: Scalars['String']['input'];
  input: CreateCustomerInput;
};


export type MutationCreateCustomerSlotArgs = {
  input: CreateCustomerSlotInput;
};


export type MutationCreateFamilyCustomerAddressArgs = {
  input: CreateFamilyCustomerAddressInput;
};


export type MutationCreateLeadArgs = {
  input?: InputMaybe<LeadInput>;
};


export type MutationDeleteFamilyCustomerAddressArgs = {
  uuid: Scalars['ID']['input'];
};


export type MutationDoChangeMailArgs = {
  token: Scalars['String']['input'];
};


export type MutationDoRenewPasswordArgs = {
  newPassword: Scalars['String']['input'];
};


export type MutationDoResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationExchangeInventoryArgs = {
  input: ReturnInventoryInput;
  shipmentUuid: Scalars['ID']['input'];
};


export type MutationExchangePreShippingArgs = {
  input: ExchangePreShippingInput;
  shipmentUuid: Scalars['ID']['input'];
};


export type MutationGeneratePaymentCustomerPortalSessionArgs = {
  familyCustomerContractedPlanUuid: Scalars['ID']['input'];
};


export type MutationPostQuestionnaireArgs = {
  customerId: Scalars['String']['input'];
  input: PostQuestionnaireInput;
};


export type MutationPostQuestionnaireWithSignInArgs = {
  input: PostQuestionnaireWithSignInInput;
};


export type MutationPurchaseShipmentToysArgs = {
  input: PurchaseShipmentToysInput;
};


export type MutationRegisterFamilyAccountArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRequestRegistrationMailArgs = {
  mail: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  mail: Scalars['String']['input'];
};


export type MutationSendGraduatesArgs = {
  referralType?: InputMaybe<ReferralType>;
  requestedDocs: Array<InputMaybe<GraduateServiceCompanies>>;
};


export type MutationSignInArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationStoreExchangeInventoryArgs = {
  input: ReturnInventoryTemporaryInput;
  shipmentUuid: Scalars['ID']['input'];
};


export type MutationUpdateCustomerBillingInfoArgs = {
  input: UpdateCustomerBillingInfoInput;
};


export type MutationUpdateFamilyCustomerAddressArgs = {
  input: UpdateFamilyCustomerAddressInput;
  uuid: Scalars['ID']['input'];
};


export type MutationUpdatePrimaryAddressArgs = {
  familyCustomerAddressUuid: Scalars['ID']['input'];
};


export type MutationVerifyPasswordArgs = {
  password: Scalars['String']['input'];
};

export enum OptionServiceType {
  /** 特急便 */
  ExpressDelivery = 'EXPRESS_DELIVERY',
  /** 特急便(無料版) */
  FreeExpressDelivery = 'FREE_EXPRESS_DELIVERY',
  /** おもちゃ1点追加 */
  OneAdditionalToy = 'ONE_ADDITIONAL_TOY',
  /** おもちゃチェンジ */
  ToyChange = 'TOY_CHANGE'
}

export type PaymentType = {
  __typename?: 'PaymentType';
  uuid: Scalars['String']['output'];
};

export type PlanPrice = {
  __typename?: 'PlanPrice';
  couponErrorReason?: Maybe<Scalars['String']['output']>;
  discountedPrice?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  subscriptionCoupon?: Maybe<SubscriptionCoupon>;
};

export type PostQuestionnaireInput = {
  applicationChannel?: InputMaybe<Scalars['String']['input']>;
  childInterest?: InputMaybe<Scalars['String']['input']>;
  livingEnvironments?: InputMaybe<Array<LivingEnvironmentInput>>;
  otherToys?: InputMaybe<Scalars['String']['input']>;
  toys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PostQuestionnaireWithSignInInput = {
  applicationChannel: Scalars['String']['input'];
  childInterest?: InputMaybe<Scalars['String']['input']>;
  livingEnvironments?: InputMaybe<Array<PostQuestionnaireWithSignInLivingEnvironment>>;
  otherToys?: InputMaybe<Scalars['String']['input']>;
  toys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PostQuestionnaireWithSignInLivingEnvironment = {
  uuid: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};

export type PurchaseShipmentToysInput = {
  familyCustomerCouponUuids?: InputMaybe<Array<Scalars['String']['input']>>;
  shipmentToyUuids: Array<Scalars['String']['input']>;
  shipmentUuid: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** 配達指定時間帯の一覧を取得する */
  deliveryMethodTimes: Array<DeliveryMethodTime>;
  /** 入金に関しての情報を返す */
  deposit: Deposit;
  /** Madras にある顧客情報を返す */
  familyCustomer: FamilyCustomer;
  /** 資料送付可能な個社を取得する */
  graduateServiceCompanies: Array<Maybe<GraduateServiceCompanies>>;
  health: Health;
  /** 買取処理済みであるか */
  isPurchasedShipmentToys: Scalars['Boolean']['output'];
  /** Ortega にある顧客情報を返す */
  me: FamilyAccount;
  /** おもちゃのプラン情報を取得する */
  planPrice?: Maybe<PlanPrice>;
  /** 顧客のアンケート回答状況を取得する */
  questionnaire: QuestionnaireResponse;
  /** 退会申請関連の情報を取得する */
  quitApplication: QuitApplication;
  /** キャッシュに一時保存されている交換申請フォームの入力内容を取得する。取得できなかった場合は null を返す */
  restoreExchangeInventory?: Maybe<ReturnInventoryTemporary>;
  /** 発送を取得する */
  shipment?: Maybe<Shipment>;
  /** 指定された契約に紐づく発送を、発送日時の降順に全件返す。追加発送は親 Shipment の additionalShipments に付与される） */
  shipments: Array<Shipments>;
  /** 「新規登録用トークン」の有効性を検証する */
  verifyRegisterAccountToken: Scalars['Boolean']['output'];
  /** 「パスワードリセット用トークン」の有効性を検証する */
  verifyResetPasswordToken: Scalars['Boolean']['output'];
};


export type QueryIsPurchasedShipmentToysArgs = {
  shipmentUuid: Scalars['ID']['input'];
};


export type QueryPlanPriceArgs = {
  id: Scalars['UUID']['input'];
  promotionCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryQuestionnaireArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryRestoreExchangeInventoryArgs = {
  shipmentUuid: Scalars['ID']['input'];
};


export type QueryShipmentArgs = {
  uuid: Scalars['ID']['input'];
};


export type QueryShipmentsArgs = {
  familyCustomerContractedPlanUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
  filter?: InputMaybe<ShipmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVerifyRegisterAccountTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryVerifyResetPasswordTokenArgs = {
  token: Scalars['String']['input'];
};

export type QuestionnaireResponse = {
  __typename?: 'QuestionnaireResponse';
  ageOfChild?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export type QuitApplication = {
  __typename?: 'QuitApplication';
  /** 退会申請可能であるかどうかのステータス */
  applicableStatus: QuitApplicationApplicableStatus;
  /** 退会申請関連の契約情報を取得する */
  familyCustomerContractedPlan: QuitApplicationFamilyCustomerContractedPlan;
};

export enum QuitApplicationApplicableStatus {
  /** イレギュラー */
  Irregular = 'IRREGULAR',
  /** 販促不可 */
  NotEligibleForPromotion = 'NOT_ELIGIBLE_FOR_PROMOTION',
  /** レギュラー */
  Regular = 'REGULAR'
}

export type QuitApplicationFamilyCustomerContractedPlan = {
  __typename?: 'QuitApplicationFamilyCustomerContractedPlan';
  contractedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  nextBillingAt?: Maybe<Scalars['DateTime']['output']>;
  returnExpiresDate?: Maybe<Scalars['Date']['output']>;
  uuid: Scalars['String']['output'];
};

export enum ReferralType {
  /** "交換申請送客 */
  ExchangeToys = 'EXCHANGE_TOYS',
  /** "卒業生送客 */
  Graduate = 'GRADUATE'
}

export type ReturnInventoryInput = {
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?type=design&node-id=5516-17223&mode=design&t=VyQNd7WBQTitGSBL-4 */
  optionServices?: InputMaybe<Array<ReturnInventoryOptionServiceInput>>;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16786 */
  questionnaire: ReturnInventoryQuestionnaireInput;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16770 */
  requests: ReturnInventoryRequestInput;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16628 */
  returnShipments: Array<ReturnShipmentInput>;
};

export type ReturnInventoryOptionServiceInput = {
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?type=design&node-id=5516-16787&mode=design&t=8zJPh42sdIkficQW-4 */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?type=design&node-id=5516-17223&mode=design&t=8zJPh42sdIkficQW-4 */
  optionServiceType: OptionServiceType;
};

export type ReturnInventoryOptionServiceTemporary = {
  __typename?: 'ReturnInventoryOptionServiceTemporary';
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?type=design&node-id=5516-16787&mode=design&t=8zJPh42sdIkficQW-4 */
  comment?: Maybe<Scalars['String']['output']>;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?type=design&node-id=5516-17223&mode=design&t=8zJPh42sdIkficQW-4 */
  optionServiceType?: Maybe<OptionServiceType>;
};

export type ReturnInventoryOptionServiceTemporaryInput = {
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?type=design&node-id=5516-16787&mode=design&t=8zJPh42sdIkficQW-4 */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?type=design&node-id=5516-17223&mode=design&t=8zJPh42sdIkficQW-4 */
  optionServiceType?: InputMaybe<OptionServiceType>;
};

export type ReturnInventoryQuestionnaireInput = {
  /** Madras: shipment_evaluations.scores cleaner, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16848 */
  cleanliness: Scalars['Int']['input'];
  /** Madras: shipment_evaluations.plan, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16836 */
  planSatisfaction: Scalars['Int']['input'];
  /** Madras: shipment_evaluations.promoter, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16807 */
  recommendation: Scalars['Int']['input'];
};

export type ReturnInventoryQuestionnaireTemporary = {
  __typename?: 'ReturnInventoryQuestionnaireTemporary';
  /** Madras: shipment_evaluations.scores cleaner, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16848 */
  cleanliness?: Maybe<Scalars['Int']['output']>;
  /** Madras: shipment_evaluations.plan, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16836 */
  planSatisfaction?: Maybe<Scalars['Int']['output']>;
  /** Madras: shipment_evaluations.promoter, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16807 */
  recommendation?: Maybe<Scalars['Int']['output']>;
};

export type ReturnInventoryQuestionnaireTemporaryInput = {
  /** Madras: shipment_evaluations.scores cleaner, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16848 */
  cleanliness?: InputMaybe<Scalars['Int']['input']>;
  /** Madras: shipment_evaluations.plan, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16836 */
  planSatisfaction?: InputMaybe<Scalars['Int']['input']>;
  /** Madras: shipment_evaluations.promoter, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16807 */
  recommendation?: InputMaybe<Scalars['Int']['input']>;
};

export type ReturnInventoryRequestInput = {
  /** Madras: shipments.comment_of_received, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16783 */
  opinionsAndRequests?: InputMaybe<Scalars['String']['input']>;
  /** Madras: shipments.child_interesting_of_received, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16778 */
  requests?: InputMaybe<Scalars['String']['input']>;
};

export type ReturnInventoryRequestTemporary = {
  __typename?: 'ReturnInventoryRequestTemporary';
  /** Madras: shipments.comment_of_received, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16783 */
  opinionsAndRequests?: Maybe<Scalars['String']['output']>;
  /** Madras: shipments.child_interesting_of_received, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16778 */
  requests?: Maybe<Scalars['String']['output']>;
};

export type ReturnInventoryRequestTemporaryInput = {
  /** Madras: shipments.comment_of_received, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16783 */
  opinionsAndRequests?: InputMaybe<Scalars['String']['input']>;
  /** Madras: shipments.child_interesting_of_received, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16778 */
  requests?: InputMaybe<Scalars['String']['input']>;
};

export type ReturnInventoryTemporary = {
  __typename?: 'ReturnInventoryTemporary';
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?type=design&node-id=5516-17223&mode=design&t=VyQNd7WBQTitGSBL-4 */
  optionServices?: Maybe<Array<ReturnInventoryOptionServiceTemporary>>;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16786 */
  questionnaire: ReturnInventoryQuestionnaireTemporary;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16770 */
  requests: ReturnInventoryRequestTemporary;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16628 */
  returnShipments: Array<ReturnShipmentTemporary>;
};

export type ReturnInventoryTemporaryInput = {
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?type=design&node-id=5516-17223&mode=design&t=VyQNd7WBQTitGSBL-4 */
  optionServices?: InputMaybe<Array<ReturnInventoryOptionServiceTemporaryInput>>;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16786 */
  questionnaire: ReturnInventoryQuestionnaireTemporaryInput;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16770 */
  requests: ReturnInventoryRequestTemporaryInput;
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16628 */
  returnShipments: Array<InputMaybe<ReturnShipmentTemporaryInput>>;
};

export type ReturnShipmentInput = {
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16633 */
  returnToys: Array<ReturnToyInput>;
  /** Madras: shipments.uuid, Figma: (none) */
  shipmentUuid: Scalars['ID']['input'];
};

export type ReturnShipmentTemporary = {
  __typename?: 'ReturnShipmentTemporary';
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16633 */
  returnToys: Array<ReturnToyTemporary>;
  /** Madras: shipments.uuid, Figma: (none) */
  shipmentUuid: Scalars['ID']['output'];
};

export type ReturnShipmentTemporaryInput = {
  /** Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16633 */
  returnToys: Array<InputMaybe<ReturnToyTemporaryInput>>;
  /** Madras: shipments.uuid, Figma: (none) */
  shipmentUuid: Scalars['ID']['input'];
};

export type ReturnToyInput = {
  /** Madras: shipment_toys.survey, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16682 */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Madras: shipment_toys.evaluation, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16678 */
  evaluation: Scalars['Int']['input'];
  /** Madras: shipment_toys.shipment_toy_status_id, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16667 */
  receiveStatus: ShipmentToyCustomerReceiveStatus;
  /** Madras: shipment_toys.uuid, Figma: (none) */
  shipmentToyUuid: Scalars['ID']['input'];
};

export type ReturnToyTemporary = {
  __typename?: 'ReturnToyTemporary';
  /** Madras: shipment_toys.survey, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16682 */
  comment?: Maybe<Scalars['String']['output']>;
  /** Madras: shipment_toys.evaluation, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16678 */
  evaluation?: Maybe<Scalars['Int']['output']>;
  /** Madras: shipment_toys.shipment_toy_status_id, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16667 */
  receiveStatus?: Maybe<ShipmentToyCustomerReceiveStatus>;
  /** Madras: shipment_toys.uuid, figma: (none)  */
  shipmentToyUuid: Scalars['ID']['output'];
};

export type ReturnToyTemporaryInput = {
  /** Madras: shipment_toys.survey, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16682 */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Madras: shipment_toys.evaluation, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16678 */
  evaluation?: InputMaybe<Scalars['Int']['input']>;
  /** Madras: shipment_toys.shipment_toy_status_id, Figma: https://www.figma.com/file/GqGMRYe7i3et3ESdCoAlkr/mypage?node-id=1327%3A16667 */
  receiveStatus?: InputMaybe<ShipmentToyCustomerReceiveStatus>;
  /** Madras: shipment_toys.uuid, figma: (none)  */
  shipmentToyUuid: Scalars['ID']['input'];
};

/** Input for the `messages` argument of `@rulesForArray`. */
export type RulesForArrayMessage = {
  /** Message to display if the rule fails, e.g. `"Must be a valid email"`. */
  message: Scalars['String']['input'];
  /** Name of the rule, e.g. `"email"`. */
  rule: Scalars['String']['input'];
};

/** Input for the `messages` argument of `@rules`. */
export type RulesMessage = {
  /** Message to display if the rule fails, e.g. `"Must be a valid email"`. */
  message: Scalars['String']['input'];
  /** Name of the rule, e.g. `"email"`. */
  rule: Scalars['String']['input'];
};

export type Shipment = {
  __typename?: 'Shipment';
  /** この発送に関連付けられた追加発送 */
  additionalShipments: Array<Shipment>;
  applyReturnAt?: Maybe<Scalars['String']['output']>;
  canReturnInventory: Scalars['Boolean']['output'];
  commentOfReceived?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deliveryMethodTime?: Maybe<DeliveryMethodTime>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  familyCustomerAddress?: Maybe<FamilyCustomerAddress>;
  familyCustomerContractedPlan?: Maybe<FamilyCustomerContractedPlan>;
  id: Scalars['ID']['output'];
  isShipped?: Maybe<Scalars['Boolean']['output']>;
  nextAvailableReturnInventoryDate?: Maybe<Scalars['String']['output']>;
  nextAvailableShippingDate?: Maybe<Scalars['String']['output']>;
  /** 保護者の方へ に表示する文言をOrtegaで生成する TODO: #764 将来的にはMadrasから文言を送ってもらいMadrassと文言を同期したい */
  noteOfPlanSheet: Array<Scalars['String']['output']>;
  parentShipment?: Maybe<Shipment>;
  planComment?: Maybe<Scalars['String']['output']>;
  planTitle?: Maybe<Scalars['String']['output']>;
  plannerMessage?: Maybe<Scalars['String']['output']>;
  relatedShipments: Array<Shipment>;
  /** 返却申請フォームのURLを返します。（返却申請が可能でない場合にも値が返ります） */
  returnApplicationFormUrl: Scalars['String']['output'];
  returnedAt?: Maybe<Scalars['String']['output']>;
  shipmentStatus?: Maybe<ShipmentStatus>;
  shipmentToys: Array<ShipmentToy>;
  shipmentType?: Maybe<ShipmentType>;
  shippedAt?: Maybe<Scalars['String']['output']>;
  specifiedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** 発送前交換申請(チラ見せ)の有効期限日時。「顧客確認中」になる前(=チラ見せ開始前)は null を返す */
  toyExchangePreShippingExpirationDate?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type ShipmentFilterInput = {
  /** 発送日時(shippedAt)の入力有無 */
  isShippedAtFilled?: InputMaybe<Scalars['Boolean']['input']>;
  /** 発送状態(ShipmentStatus) の UUID のリスト */
  statusUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** 発送種類(ShipmentType) の UUID のリスト */
  typeUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ShipmentStatus = {
  __typename?: 'ShipmentStatus';
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type ShipmentToy = {
  __typename?: 'ShipmentToy';
  individualToy?: Maybe<IndividualToy>;
  shipmentToyCustomerReceiveStatus?: Maybe<ShipmentToyCustomerReceiveStatus>;
  shipmentToyStatus?: Maybe<ShipmentToyStatus>;
  toy: Toy;
  uuid: Scalars['ID']['output'];
};

export enum ShipmentToyCustomerPreShippingStatus {
  /** すでに持っている */
  AlreadyHave = 'ALREADY_HAVE',
  /** "このままでよい */
  GoodAsItIs = 'GOOD_AS_IT_IS'
}

export enum ShipmentToyCustomerReceiveStatus {
  /** 継続 */
  Continuation = 'CONTINUATION',
  /** "購入 */
  Purchase = 'PURCHASE',
  /** 返却 */
  Return = 'RETURN'
}

export type ShipmentToyStatus = {
  __typename?: 'ShipmentToyStatus';
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type ShipmentType = {
  __typename?: 'ShipmentType';
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type Shipments = {
  __typename?: 'Shipments';
  familyCustomerContractedPlanUuid: Scalars['ID']['output'];
  shipments: Array<Shipment>;
};

export enum SignInResult {
  /** サインイン失敗 ※現時点ではサインイン失敗時は errors で情報を返しているため使われない */
  Fail = 'FAIL',
  /** パスワード認証は成功したが二段階認証が必要 */
  RequireMfa = 'REQUIRE_MFA',
  /** サインイン成功 */
  Success = 'SUCCESS'
}

export type SubscriptionCoupon = {
  __typename?: 'SubscriptionCoupon';
  discountAmount: Scalars['Int']['output'];
  duration: Scalars['String']['output'];
  durationInMonths?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type SubscriptionInfo = {
  __typename?: 'SubscriptionInfo';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
};

/** サムネイル画像のURLはルールに従ってOBで生成する（将来的にはサムネイル生成するMBからURLをもらいたい） */
export type ThumbnailImage = {
  __typename?: 'ThumbnailImage';
  pathFixed128: Scalars['String']['output'];
  pathFixed256: Scalars['String']['output'];
  pathFixed512: Scalars['String']['output'];
  pathFixed1280: Scalars['String']['output'];
};

export type Toy = {
  __typename?: 'Toy';
  /** セット内容 */
  accessories?: Maybe<Scalars['String']['output']>;
  /** おもちゃ説明文 */
  commentOfPlanSheet?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nameOfPlanSheet?: Maybe<Scalars['String']['output']>;
  /** ご留意事項 Madrasから受信したnoteOfPlanSheetに、バッテリーに関する留意事項を加えてfrontに送る TODO: #764 将来的にはMadrasから文言を送ってもらいMadrassと文言を同期したい */
  noteOfPlanSheet: Array<Scalars['String']['output']>;
  secondHandsPrice?: Maybe<Scalars['Int']['output']>;
  toyImages: Array<ToyImage>;
  toyMaker?: Maybe<ToyMaker>;
  toyMakerNameOfPlanSheet?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type ToyCoupon = {
  __typename?: 'ToyCoupon';
  couponNumber: Scalars['String']['output'];
  couponStatus: CouponStatus;
  expiresAt?: Maybe<Scalars['String']['output']>;
  isCombinable: Scalars['Boolean']['output'];
  issuedAt: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  usedAt?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type ToyImage = {
  __typename?: 'ToyImage';
  path: Scalars['String']['output'];
  thumbnailImage: ThumbnailImage;
  uuid: Scalars['ID']['output'];
};

export type ToyMaker = {
  __typename?: 'ToyMaker';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nameOfPronunciation?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type UpdateCustomerBillingInfoInput = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  buildingName: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  pref: Scalars['String']['input'];
  tel: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type UpdateFamilyCustomerAddressInput = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  buildingName: Scalars['String']['input'];
  deliveryMethodTime?: InputMaybe<FamilyCustomerAddressDeliveryMethodTimeInput>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  pref: Scalars['String']['input'];
  tel: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    