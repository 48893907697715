import { HStack, type StackProps, Icon } from "@chakra-ui/react";
import { type FC } from "react";
import { Typography } from "@/components/essentials/typography";
import {
  noticeButtonTestId,
  noticeButtonIconTestId,
  noticeButtonArrwoIconTestId,
  noticeButtonTypographyTestId,
} from "./const";
import { FaAngleRight, FaExclamationCircle } from "react-icons/fa";
import Link, { type LinkProps } from "next/link";

const variant = {
  bgColor: "light.main",
  borderColor: `error.main`,
  borderWidth: "1px",
  borderRadius: "0.5rem",
};

const color = "error.main";

export interface NoticeButtonProps extends StackProps {
  readonly href: LinkProps["href"];
}

export const NoticeButton: FC<NoticeButtonProps> = ({ href, children, ...props }) => {
  return (
    <HStack as={Link} href={href} p="1rem" {...variant} data-testid={noticeButtonTestId} {...props}>
      <Icon as={FaExclamationCircle} size={32} color={color} data-testid={noticeButtonIconTestId} />
      <Typography variant="display6" color={color} data-testid={noticeButtonTypographyTestId} flexGrow={1}>
        {children}
      </Typography>
      <Icon as={FaAngleRight} size={32} color={color} data-testid={noticeButtonArrwoIconTestId} />
    </HStack>
  );
};
