import { Typography } from "@/components/essentials/typography";
import { Box } from "@/components/essentials/box";
import { type FC } from "react";
import { Center, Flex } from "@chakra-ui/react";
import {
  quitApplicationSubmittedButtonTestId,
  quitApplicationSubmittedTestId,
  quitApplicationSubmittedTypographyTestId,
} from "./const";
import Link from "next/link";
import { Button } from "@/components/essentials/button";
import { toysubQuitApplicationCancelUrl } from "@/configs/externalUrls";
import { format, sub } from "date-fns";

interface QuitApplicationSubmittedProps {
  readonly nextBillingAt: Date | undefined;
}

export const QuitApplicationSubmitted: FC<QuitApplicationSubmittedProps> = ({ nextBillingAt }) => {
  return (
    <Center>
      <Flex
        data-testid={quitApplicationSubmittedTestId}
        flexDir="column"
        gridRowGap={2}
        w="100%"
        p={2}
        justifyContent="center"
        alignItems="center"
        bgColor="background.alto.20"
        borderRadius="8px"
      >
        <Box data-testid={quitApplicationSubmittedTypographyTestId} textAlign="center">
          <Typography variant="display3" mt="2" mb="3">
            退会処理を進めています
          </Typography>
          <Typography variant="text4" color="mono.dark.54" mb="0.5rem">
            {nextBillingAt ? (
              <strong>次回課金基準日の前日の{format(sub(nextBillingAt, { days: 1 }), "M月d日")}（必着）までに、</strong>
            ) : (
              "おもちゃ返却期限日までに、"
            )}
            おもちゃをご返却ください。
          </Typography>
          <Typography variant="text4" color="mono.dark.54" mb="0.5rem">
            おもちゃの返却を確認次第、お手続きを進めさせていただきます。
          </Typography>
          <Typography variant="text4" color="mono.dark.54" mb="0.5rem">
            退会申請をキャンセルする場合はお問い合わせください。
          </Typography>
        </Box>
        <Center h="3rem">
          <Button
            as={Link}
            href={toysubQuitApplicationCancelUrl}
            data-testid={quitApplicationSubmittedButtonTestId}
            variant="solid"
          >
            退会申請をキャンセルする
          </Button>
        </Center>
      </Flex>
    </Center>
  );
};
